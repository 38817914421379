import baseAuth from "./baseAuth";
import {JWT} from 'storwork-stores'

export const refreshToken = callback => {
  var query = `
  {
    refreshToken
  }`;
  var vars = [];
  baseAuth(query, vars, data => {
    JWT.keep(data.data.refreshToken);
    callback();
  });
};
