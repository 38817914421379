import React, {Component} from 'react'
import Form from 'react-jsonschema-form'
import {Link} from 'react-router-dom'
import {createActionForm} from './Forms/create'
import {getAction, updateAction} from '../services/actions'
// import FormBuilder from '../components/FormBuilder'
import FormBuilder from '../components/FormBuilder'

const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

export default class ActionEdit extends Component {
  state = {
    action: null
  };

  onSubmit = ({formData}) => {
    formData.extras = ''
    updateAction(this.state.action.id, formData, response => {
      if (response.hasOwnProperty('errors')) {
        alert('dane nie zapisane')
        // TODO do podmiany
      } else {
        alert('dane zapisane')
      }
      console.log(response)
    })
  };

  componentDidMount () {
    if (!this.props.match.params.id) return
    getAction(this.props.match.params.id, action => {
      this.setState({
        action: action.data.repository.expertActions.data[0]
      })
    })
  }

  render () {
    if (!this.state.action) return null

    const action = this.state.action
    console.log(action)
    const schema = createActionForm()
    const jsonSchema = schema.json
    const uiSchema = schema.ui

    const formData = {
      type: action.type,
      name: action.name,
      description: action.description ? action.description : '',
      validation: action.validation ? action.validation : '',
      logic: action.logic,
      json_schema: b64DecodeUnicode(action.json_schema),
      ui_schema: b64DecodeUnicode(action.ui_schema)
    }

    const widgets = {
      formBuilder: FormBuilder
    };

    return (
      <div className="bootstrap">
        <div className="row" style={{padding: '24px 0'}}>
          <div className={'col-sm-2 pull-left'}></div>
          <div className={'col-sm-8'}>
            <Form schema={jsonSchema}
              uiSchema={uiSchema}
              onSubmit={this.onSubmit}
              formData={formData}
              widgets={widgets}
            >
              <input type="submit" value={'Zapisz'} className={'btn btn-info pull-right'} />
              <Link to={`/list-action`} className={'btn btn-primary pull-left'}>Powrót do listy</Link>
            </Form>
          </div>
          <div className={'col-sm-2 pull-left'}></div>
        </div>
      </div>
    )
  }
}
