import React from 'react'
import Form from 'react-jsonschema-form'
import 'react-table/react-table.css'

import {createActionForm} from '../Actions/Forms/create'
import {createAction} from '../services/actions'
import FormBuilder from "../components/FormBuilder";

export default class ActionsList extends React.Component {
  state = {
    actions: [],
    showModal: false,
    activeProcess: 0
  }

  onSubmit = ({formData}) => {
    createAction(formData, data => {
      console.log(formData)
      if (data.hasOwnProperty('errors')) {
        alert('dane nie zapisane')
      } else {
        window.location.href = '/'
      }
      console.log(data)
    })
  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  validate = (formData, errors) => {
    if (!this.IsJsonString(formData.json_schema)) {
      errors.json_schema.addError('JSON is not valid')
    }
    return errors
  }

  render () {
    const schema = createActionForm()
    const jsonSchema = schema.json
    const uiSchema = schema.ui

    const widgets = {
      formBuilder: FormBuilder
    };

    return (
      <div className={'bootstrap'}>
        <div className="row" style={{padding: '24px 0'}}>
          <div className={'col-sm-2 pull-left'}></div>
          <div className={'col-sm-8'}>
            <Form schema={jsonSchema}
              uiSchema={uiSchema}
              validate={this.validate}
              onSubmit={this.onSubmit}
              widgets={widgets}
            >
              <input type="submit" value={'Zapisz'} className={'btn btn-info pull-right'} />
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
