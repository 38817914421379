import React, {Component} from 'react'
import _ from 'lodash'
import {createAction, getAction, updateAction} from '../services/actions'
const emptyField = {
  title: 'Question 1',
  type: 'string',
  isRequired: false
}

export default class ActionGenerate2 extends Component {
  state = {
    id: false,
    isLoading: true,
    actionName: 'Action Name',
    actionType: 'start',
    formName: 'Form name',
    variantData: {
      statement: 'Ilość zdobytych punktów to {{points}} z 20. {{description}}. Powodzenia następnym razem',
      pointsRanges: [{from: 0, to: 3, description: 'słaby wynik'}, {from: 4, to: 6, description: 'dobry wynik'}, {from: 7, to: 10, description: 'bardzo dobry wynik'}]
    },
    actionVariant: 'quiz',
    values: [
      _.clone(emptyField)
    ]
  };

  componentWillReceiveProps (nextProps) {
    this.setState({
      id: false,
      isLoading: true,
      actionName: 'Action Name',
      actionType: 'start',
      formName: 'Form name',
      variantData: {
        statement: 'Ilość zdobytych punktów to {{points}} z 20. {{description}}. Powodzenia następnym razem',
        pointsRanges: [{from: 0, to: 3, description: 'słaby wynik'}, {from: 4, to: 6, description: 'dobry wynik'}, {from: 7, to: 10, description: 'bardzo dobry wynik'}]
      },
      actionVariant: 'quiz',
      values: [
        _.clone(emptyField)
      ]
    })
  }

  componentDidMount () {
    if (!this.props.match.params.id) return

    const action = getAction(this.props.match.params.id, action => {
      const data = action.data.repository.expertActions.data[0]
      console.log(data)
      const extras = JSON.parse(action.data.repository.expertActions.data[0].extras)

      this.setState({
        id: data.id,
        actionName: data.name,
        actionType: extras.actionType,
        formName: extras.formName,
        actionVariant: extras.actionVariant,
        values: extras.values,
        isLoading: false
      })
      if (extras.actionVariant === 'quiz') {
        this.setState({
          variantData: extras.variantData
        })
      }
    })
  }

  changeAction (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState(state => {
      state[name] = value
      return state
    })
  }

  slugify (str) {
    const from  = "ąàáäâãåæćęęèéëêìíïîłńòóöôõøśùúüûñçżź",
      to    = "aaaaaaaaceeeeeeiiiilnoooooosuuuunczz",
      regex = new RegExp('[' + from.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1') + ']', 'g');

    if (str === null) return ''

    str = String(str).toLowerCase().replace(regex, function (c) {
      return to.charAt(from.indexOf(c)) || '-'
    })

    return str.replace(/[^\w\s-]/g, '').replace(/([A-Z])/g, '-$1').replace(/[-_\s]+/g, '_').toLowerCase()
  };

  changeField (i, event) {
    event.persist()
    // console.log(event.target.value);
    this.setState(state => {
      if (event.target.type === 'checkbox') {
        this.state.values[i][event.target.name] = event.target.checked
      } else {
        this.state.values[i][event.target.name] = event.target.value
      }
      if (event.target.value === 'radio' || event.target.value === 'checkbox') {
        this.state.values[i]['fields'] = [{title: 'option 1', point: 0}, {title: 'option 2', point: 0}, {title: 'option 3', point: 0}]
      }
      // this.state.values[i][event.target.name] =
      //   event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      return state
    })
  }

  addField (i, event) {
    event.persist()
    this.setState(state => {
      state.values.push({
        title: `Question ${i + 1}`,
        type: 'string',
        isRequired: false
      })
      return state
    })
  }

  removeField (i) {
    if (this.state.values.length > 1) {
      this.setState(state => {
        state.values.splice(i, 1)
        return state
      })
    }
  }

  changeEmbeddedField (i, ii, event) {
    // console.log(event.target.name);
    event.persist()
    this.setState(state => {
      this.state.values[i]['fields'][ii][event.target.name] = event.target.value
      return state
    })
  }

  removeEmbeddedField (i, ii) {
    // console.log(this.state.values[i]['fields'][ii]);
    if (this.state.values[i]['fields'].length > 1) {
      this.setState(state => {
        state.values[i]['fields'].splice(ii, 1)
        return state
      })
    }
  }

  addEmbeddedField (i, ii) {
    this.setState(state => {
      state.values[i]['fields'].push({
        title: `option ${ii + 2}`,
        point: 0
      })
      return state
    })
  }

  changeStatement (event) {
    event.persist()
    this.setState(state => {
      this.state.variantData.statement = event.target.value
      return state
    })
  }

  changePointsRanges (i, event) {
    event.persist()
    this.setState(state => {
      this.state.variantData.pointsRanges[i][event.target.name] = event.target.value
      return state
    })
  }

  removePointsRanges (i) {
    this.setState(state => {
      state.variantData.pointsRanges.splice(i, 1)
      return state
    })
  }

  addPointsRanges () {
    this.setState(state => {
      state.variantData.pointsRanges.push({
        from: 0,
        to: 0,
        description: ''
      })
      return state
    })
  }

  generateJson () {
    let jsonSchema, uiSchema
    let required = []
    let valuesSchema = []
    let valuesUi = []
    let elementSchema = ''
    let elementUi = ''
    const formName = this.state.formName

    this.state.values.map((value, index) => {
    // let title = value.title.split(' ').join('_');
      let title = this.slugify(value.title)

      if (value.type === 'string' || value.type === 'integer' || value.type === 'boolean') {
        elementSchema = `"${title}": { "type":"${value.type}", "title":"${value.title}"}`
      } else if (value.type === 'radio') {
        let enumlist = []
        let enumNames = []
        value['fields'].map((field) => {
          enumlist.push(`"${this.slugify(field['title'])}"`)
          enumNames.push(`"${field['title']}"`)
        })
        elementSchema = `"${title}": { "type":"string", "title":"${value.title}", "enum":[${enumlist.join()}], "enumNames":[${enumNames.join()}]}`
        elementUi = `"${title}": { "ui:widget": "radio"}`
      } else if (value.type === 'checkbox') {
        let enumlist = []
        let enumNames = []
        value['fields'].map((field) => {
          enumlist.push(`"${this.slugify(field['title'])}"`)
          enumNames.push(`"${field['title']}"`)
        })
        elementSchema = `"${title}": { "type":"array", "title":"${value.title}", "items": { "enum":[${enumlist.join()}], "enumNames":[${enumNames.join()}], "type": "string"}, "uniqueItems": true }`
        elementUi = `"${title}": { "ui:widget": "checkboxes"}`
      }
      if (value['isRequired'] === true) {
        required.push(`"${title}"`)
      }
      // console.log(elementSchema);
      valuesSchema.push(elementSchema)
      if (elementUi !== '') {
        valuesUi.push(elementUi)
      }
    }
    )

    jsonSchema = `{"type": "object", "required": [${required.join()}],"title": "${formName}", "properties": { ${valuesSchema} } }`
    uiSchema = `{ ${valuesUi} }`
    //  console.log(jsonSchema);
    // console.log(uiSchema);
    return {
      jsonSchema: jsonSchema,
      uiSchema: uiSchema
    }
  }

  generateLogic () {
    // console.log(this.state.variantData.pointsRanges);
    let logic = `$context["points"] = 0; $points = &$context["points"]; $description =''; `
    let ranges = ''
    let statement = ''
    this.state.values.map((value, index) => {
      if (value.type === 'radio') {
        value['fields'].map((field) => {
          logic += ` if ( isset($context["${this.slugify(value.title)}"]) && $context["${this.slugify(value.title)}"] == "${this.slugify(field['title'])}") {$context["points"] += ${parseInt(field['point'])};} `
        })
      } else if (value.type === 'checkbox') {
        value['fields'].map((field) => {
          logic += ` if ( isset($context["${this.slugify(value.title)}"]) && in_array("${this.slugify(field['title'])}", $context["${this.slugify(value.title)}"]) ) {$context["points"] += ${parseInt(field['point'])};} `
        })
      }
    })
    this.state.variantData.pointsRanges.map((range) => {
      ranges += ` if ( $context["points"] >= ${parseInt(range.from)} && $context["points"] <= ${parseInt(range.to)}) { $description = "${String(range.description)}"; }`
    })
    logic += ranges
    logic += ` return ["${this.state.variantData.statement.replace("{{points}}", "$points").replace("{{description}}", "$description")}", 'final', $context];`
    console.log(logic)
    return logic
  }

  handleSubmit (event) {
    let logic = 'return ["dane zapisane", "final", $context];'
    const schema = this.generateJson()
    if (this.state.actionVariant === 'quiz') {
      logic = this.generateLogic()
    }
    const extras = JSON.stringify(_.clone(this.state))
    const formData = {name: this.state.actionName, description: '', type: this.state.actionType, logic: logic, extras: extras, json_schema: schema.jsonSchema, ui_schema: schema.uiSchema}

    if (this.props.match.params.id) {
      updateAction(this.state.id, formData, data => {
        if (data.hasOwnProperty('errors')) {
          alert ('dane nie zapisane')
        } else {
          alert('dane zapisane')
        }
        console.log(data)
        //window.location.replace(`/app/generate-action2/${this.props.match.params.id}`)
      })
    } else {
      createAction(formData, data => {
        if (data.hasOwnProperty('errors')) {
          alert ('dane nie zapisane')
        } else {
          alert('dane zapisane')
        }
        console.log(data.data.createExpertAction)
        window.location.replace(`/app/generate-action2/${data.data.createExpertAction}`)
      })
    }
    event.preventDefault()
  }

  render () {
    if (this.state.isLoading && this.props.match.params.id) return <div><h2>Loading...</h2></div>
    return (
      <div className='pull-left col-sm-6' >
        <form onSubmit={this.handleSubmit}>
          <div>
            <label>
              action variant:
              <select name='actionVariant' value={this.state.actionVariant} onChange={(e) => this.changeAction(e)}>
                <option value='none'>none</option>
                <option value='quiz'>quiz</option>
              </select>
            </label>
          </div>
          <div>
            <label>
            action name:
              <input
                name='actionName'
                type='text'
                value={this.state.actionName}
                onChange={(e) => this.changeAction(e)}
              />
            </label>
          </div>
          <div>
            <label>
              action type:
              <select name='actionType' value={this.state.actionType} onChange={(e) => this.changeAction(e)}>
                <option value='start'>start (początkowa akcja)</option>
                <option value='normal'>normal (akcja pośrednia)</option>
                <option value='final'>final (ostatnia akcja)</option>
                <option value='main'>main (tylko jedna akcja)</option>
              </select>
            </label>
          </div>
          {
            (this.state.actionVariant === 'quiz') &&
            <div>
              <p>points ranges:</p>
              {this.state.variantData.pointsRanges.map((el, i) => <div key={i}>
                <label>
                 from:
                  <input
                    name='from'
                    type='number'
                    min='-9999' max='9999'
                    value={el['from']}
                    onChange={(e) => this.changePointsRanges(i, e)}
                  />
                </label>
                <label>
                 to:
                  <input
                    name='to'
                    type='number'
                    min='-9999' max='9999'
                    value={el['to']}
                    onChange={(e) => this.changePointsRanges(i, e)}
                  />
                </label>
                <label>
                 description:
                  <input
                    name='description'
                    type='text'
                    value={el['description']}
                    onChange={(e) => this.changePointsRanges(i, e)}
                  />
                </label>
                <input type='button' value='remove' onClick={(e) => this.removePointsRanges(i)} />
              </div>
              )}
              <div><input type='button' value='add range' onClick={(e) => this.addPointsRanges()} style={{marginLeft: '220px'}} /></div>
              <label>
                <p>template for final statement:</p>
                <textarea name='statement' value={this.state.variantData.statement} onChange={(e) => this.changeStatement(e)} rows={4} cols={50} />
              </label>
            </div>
          }
          <label>
            form name:
            <input
              name='formName'
              type='text'
              value={this.state.formName}
              onChange={(e) => this.changeAction(e)}
            />
          </label>
          {this.state.values.map((el, i) => <div key={i}>
            <label style={{paddingRight: '5px'}}>
            type:
              <select
                name='type'
                value={el.type}
                onChange={(e) => this.changeField(i, e)}>
                <option value='boolean'>boolean</option>
                <option value='string'>string</option>
                <option value='integer'>integer</option>
                <option value='radio'>radio</option>
                <option value='checkbox'>checkbox</option>
              </select>
            </label>
            <label style={{paddingRight: '5px'}}>
              label:
              <input
                name='title'
                type='text'
                value={el.title}
                onChange={(e) => this.changeField(i, e)}
              />
            </label>
            <label style={{paddingRight: '5px'}}>
              is required:
              <input
                name='isRequired'
                type='checkbox'
                checked={el.isRequired}
                onChange={(e) => this.changeField(i, e)}
              />
            </label>
            <input type='button' value='remove' onClick={(e) => this.removeField(i)} />
            {(el.type === 'radio' || el.type === 'checkbox') &&
              el.fields.map((element, ii) => <div key={ii}>
                <label>
                  title:
                  <input
                    name='title'
                    type='text'
                    value={element['title']}
                    onChange={(e) => this.changeEmbeddedField(i, ii, e)}
                  />
                  {
                    (this.state.actionVariant === 'quiz') &&
                    <label>
                       point:
                      <input
                        name='point'
                        type='number'
                        min='-999' max='999'
                        value={element['point']}
                        onChange={(e) => this.changeEmbeddedField(i, ii, e)}
                      />
                    </label>
                  }
                </label>
                <input type='button' value='remove' onClick={(e) => this.removeEmbeddedField(i, ii)} />
                {el.fields.length === ii + 1 &&
                  <div><input type='button' value='add' onClick={(e) => this.addEmbeddedField(i, ii)} style={{marginLeft: '220px'}} /></div>
                }
              </div>
              )
            }
          </div>
          )
          }
          <br />
          <div><input type='button' value='add field' onClick={(e) => this.addField(this.state.values.length, e)} style={{marginLeft: '460px'}} /></div>
          <input type='submit' value='save' onClick={(e) => this.handleSubmit(e)} />
        </form>
      </div>
    )
  }
}

// ReactDOM.render(<App />, document.getElementById('container'));
