import React,{Component} from 'react';
import Form from "react-jsonschema-form";
import jQuery from 'jquery';
import * as Scroll from 'react-scroll';
import _ from 'lodash';

import {registerProcess, toStepConverter, updateProcess, getProcess, backProcess} from "../services/core";
import Toast from "./Toast";
import logo from "../Img/logo.png"

import styles from "./CustomForm.css";
import styled from 'styled-components';
// import 'bulma/css/bulma.css';

const Wrapper = styled.div`
  margin 100px auto;
  padding: 50px;
  border: 1px solid #487eb0;
  border-radius: 3px;
  max-width: 50%;
  color: #487eb0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
`;

const Press = styled.span`
  font-size: 14px;
  color: #718093;
  margin-right: 5px;
`
const Enter = styled.button`
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  background: #487eb0;
  color: #fff;
  padding: 3px 6px;
`
const Results = styled.div`
  font-size: 20px;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

class CustomForm extends Component {
    state = {
        jsonSchema: null,
        uiSchema: null,
        paddingTop: 100,
        scrollPosition: 0,
        values: [],
        showEnterButton: false,
        errorsVisible: false,
        actions: []
    };

    questions = {}
    inputs = {}
    indicies = []

    componentDidMount() {
      window.addEventListener("beforeunload", this.onUnload);
      setTimeout(() => this.forceUpdate(), 300);
      let link = this.props.match.params.link;
      if(link) {
          getProcess(link, this);
      }
    }

    componentWillReceiveProps(nextProps) {
      window.addEventListener("beforeunload", this.onUnload);
      let link = nextProps.match.params.link;
      if(link) {
          getProcess(link, this);
      }
    }

    componentWillUnmount() {
      window.removeEventListener("beforeunload", this.onUnload);
    }

    onUnload = (event) => {
      const message = "Po odświeżeniu strony stracisz dane fomularza";
      event.returnValue = "Po odświeżeniu strony stracisz dane fomularza";
      return message;
    }


    // handleClickOnEnterButton = (event) => {
    //     event.preventDefault();
    //     const element = this.checkHighlighted() + 1;
    //
    //     if (this.indicies.length === element ) {
    //         //jQuery('#submitBtn').click();
    //         this.scrollToError();
    //     } else {
    //         this.scrollToElement(this.indicies[element]);
    //     }
    // }

    // handleKeydown = (event) => {
    //   if (event.keyCode === 13) { // If ENTER or Tab
    //     event.preventDefault();
    //     const element = this.checkHighlighted() + 1;
    //     if (this.indicies.length === element ) {
    //         jQuery('#submitBtn').click();
    //         this.scrollToError();
    //     } else {
    //         this.scrollToElement(this.indicies[element]);
    //     }
    //   }
    //   if(event.keyCode === 9) {
    //       event.preventDefault();
    //       const element = this.checkHighlighted() + 1;
    //       if (this.indicies.length === element) {
    //           this.scrollToElement(this.indicies[0]);
    //       } else {
    //           this.scrollToElement(this.indicies[element]);
    //       }
    //   }
    // }



    onSubmit = ({formData}) => {
      this.setState(state => {
        state.formData = _.merge(state.formData, formData);
        state.scrollPosition = 0;
        return state;
      },() => {
        let link = this.props.match.params.link;
        if (this.state.status != "finish" && this.state.status != "inProgress") {
          registerProcess(link, JSON.stringify(this.state.formData), this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
           })
        }else if(this.state.status != "finish" && this.state.status == "inProgress"){
          updateProcess(this.state.token, this.state.formData, this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
          })
        }
      })
    };

    previousAction = () => {
      this.setState(state => {
        state.scrollPosition = 0;
        return state;
      },() => {
        if(this.state.status != "finish" && this.state.status == "inProgress"){
          backProcess(this.state.token, this.state.formData, 0, this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
          })
        }
      })
    };

    concreteAction = (event, id) => {
      event.preventDefault();
      this.setState(state => {
        state.scrollPosition = 0;
        return state;
      },() => {
        if(this.state.status != "finish" && this.state.status == "inProgress"){
          backProcess(this.state.token, this.state.formData, id, this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
          })
        }
      })
    };

    customFieldTemplate = (props) => {
        const {id, classNames, label, help, required, description, errors, children, safeRenderCompletion, rawErrors=[]} = props;
        if (id != 'root' && !_.find(this.indicies, el => el == id) && id != undefined) {
          this.indicies.push(id);
        }

        return (
          <div
            className={"businessQuestions"}
            ref={div => {if (id != 'root') this.questions[id] = div;}}
          >
              {id == "root"? null:
                <label
                  className={classNames}
                  style={{fontWeight: "500", fontSize: "19px", color: "#487eb0", marginBottom: 40}}
                  htmlFor={id}
                >
                  {label}{required ? "*" : null}
                </label>
              }
              {description}
              {children}
              {rawErrors.map(error => <div key={id} className={"errorScrollTo"} datafield={id} style={{color: "red", fontsize: 15, fontWeight:500, marginTop: "15px", marginLeft: "140px", position: "absolute"}}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
              </div>)}
              {help}
          </div>
        );
    }

    _onChange = ({ target: { value } }, props) => {
      return props.onChange(value === "" ? "" : value);
    };

   asNumber = (value) => {
      const n = Number(value);
      const valid = typeof n === "number" && !Number.isNaN(n);
    return valid ? n : value;
    }

    NumberField = (props) => {
      // console.log("props",props.registry.fields.StringField);
      const { StringField } = props.registry.fields;
      return (
        <div>
          <StringField
            {...props}
            onChange={value => props.onChange(this.asNumber(value))}
          />
        </div>
      );
    }

    customString = (props) => {

      const valueFromPlaceholder = props.placeholder.slice(11);
      // console.log("placeholder", props.required);
      return (
        <div>
          <input
            type="text"
            className={"customInput"}
            ref={input => {if (props.id != 'root') this.inputs[props.id] = input}}
            value={props.value == null ? "" : props.value}
            required={props.required}
            onChange={(e) => this._onChange(e, props)}
            onKeyDown={this.handleKeydown}
            placeholder={props.placeholder}
            />

        </div>
      );
  }

    customCheckbox = (props) => {
      return (
        <div>
          <input
            type="checkbox"
            ref={input => {if (props.id != 'root') this.inputs[props.id] = input}}
            required={props.required}
            onChange={event => props.onChange(event.target.checked)}
            onKeyDown={this.handleKeydown}
            checked={typeof props.value === "undefined" ? false : props.value}
          />
        </div>
      );
  }

  customRadio = (props) => {
    const {options, value, required, disabled, readonly, autofocus, onChange} = props;
    const name = Math.random().toString();
    const { enumOptions, inline } = options;
    return (
      <div style={{color: "#487eb0"}}>
        {enumOptions.map((option, i) => {
           const checked = option.value === value;
           const disabledCls = disabled || readonly ? "disabled" : "";

           const radio = (
            <div  className={checked? " animated fadeIn businessRadioFont": " businessRadioFont" }>
              <input
                 type="radio"
                 checked={checked}
                 name={name}
                 required={required}
                 value={option.value}
                 onChange={event => {onChange(option.value);}}
                 onKeyDown={this.handleKeydown}
                 />
               <span className={"customRadioSpan"}>{option.label}</span>
            </div>
          );
              return  (
                <div>
                  <label key={i}>
                    {radio}
                  </label>
                </div>

              )
        })}
      </div>
    )
  }

  changeForm = ({formData}) => {
    jQuery("#submitBtn").attr("disabled", false);
      this.setState(state => {
        state.formData = _.merge(state.formData, formData);
        return state;
      })
  }

  TitleField = (props) => {
    // return null;
    const { id, title, required } = props;
    const legend = required ? title + "*" : title;
    return <legend style={{fontWeight: "800", color: "#4975AB"}} id={id}>{legend}</legend>;
  }

  transformErrors = (errors) => {
    jQuery("#submitBtn").attr("disabled", true);
    return errors.map(error => {
      if (error.name === "required") {
        error.message = "To pole jest wymagane"
      }
      return error;
    });
  }

  steps = () => {
    //console.log("akje",this.state.actions);
    let array = JSON.parse(this.state.description);
    let steps = array.map( (el, i) => {
      return(
        <div key={i} className={ this.state.actions[i] ? el.active == "yes" ? "step-item is-completed is-success is-active" : "step-item is-completed is-success" : "step-item" }>
          <div className="step-marker">
            <span className="icon">
              <i className="fa fa-check"></i>
            </span>
          </div>
          {this.state.actions[i] ? (
            <div className="step-details">
              <a href="#" onClick={(event) => this.concreteAction(event, this.state.actions[i])}>
                <p className="step-title" style={{color:"rgb(72, 126, 176)"}}>{el.titleStep}</p>
                <p style={{color:"rgb(72, 126, 176)"}}>{el.subtitleStep}</p>
              </a>
            </div>
          ) : (
            <div className="step-details">
              <p className="step-title" style={{color:"rgb(72, 126, 176)"}}>{el.titleStep}</p>
              <p style={{color:"rgb(72, 126, 176)"}}>{el.subtitleStep}</p>
            </div>

          )}
        </div>
      )
    })
    return steps;
  }

  showResults = () => {
    const {podatnosc_sd, podatnosc_si, podatnosc_sp, prawdopodobienstwo_p, ryzyko_cd, ryzyko_ci, ryzyko_cp} = this.state.formData;

    let rp, rk, sdc, sic, spc;
    sdc=sic=spc=0;

     if (podatnosc_sd > 0 && ryzyko_cd > 0) {
       sdc = podatnosc_sd/ryzyko_cd;
     }
     if (podatnosc_si > 0 && ryzyko_cp > 0) {
       sic = podatnosc_si/ryzyko_cp;
     }
     if (podatnosc_sp > 0 && ryzyko_ci > 0) {
       spc = podatnosc_sp/ryzyko_ci;
     }
     rp = prawdopodobienstwo_p * (podatnosc_sd + podatnosc_si + podatnosc_sp);
     rk = prawdopodobienstwo_p * (sdc + sic + spc);

    return <Results>
      <div>Poziom pierwotny ryzyka: {rp}</div>
      <div>Poziom końcowy ryzyka: {rk}</div>
    </Results>
  }

    render() {
        // console.log(this.state.jsonSchema);
        // console.log(this.state.uiSchema);
        // console.log(this.state.errorsVisible);
        // console.log(this.state.description);
        // console.log(this.state);
        const widgets = {
          CheckboxWidget: this.customCheckbox,
          BaseInput: this.customString,
          RadioWidget: this.customRadio
        }

        if(!this.state.jsonSchema) return null;

        if(this.state.status === 'finish')
            return (<Wrapper><Toast text={this.state.result}/></Wrapper>);

        return (
          <div style={{overflow:"hidden", margin: "0 auto", paddingTop: "50px",fontFamily: "'Raleway', sans-serif"}}>

            <div className="steps">
              {(this.state.description && this.state.description != "NULL") && this.steps()}
            </div>

            <div>
              {this.state.formData && (typeof this.state.formData.rk !== 'undefined' && typeof this.state.formData.rp !== 'undefined') && this.showResults()}
            </div>

          <div
              className={"scroll1 centeredQuestions"}
              id='container'
            >
                <Form
                  schema={this.state.jsonSchema}
                  uiSchema={this.state.uiSchema}
                  FieldTemplate={this.customFieldTemplate}
                  onChange={this.changeForm}
                  widgets={widgets}
                  validate={eval(this.state.validation) }
                  fields={{
                    NumberField: this.NumberField,
                    TitleField: this.TitleField
                  }}
                  formContext={{scroll: this.state.scrollPosition}}
                  formData={this.state.formData}
                  onSubmit={this.onSubmit}
                  showErrorList={false}
                  id="main-form"
                  noHtml5Validate
                  transformErrors={this.transformErrors}
                >


                  <button type="button" id="backBtn" className={"btn btn-info"} onClick={this.previousAction}>poprzednie</button>
                  <button type="submit" id="submitBtn" className={"btn btn-info"} style={{float: "right"}}>następne</button>

                  <div style={{height: "120px"}}/>
                </Form>
            </div>
            </div>
        );

    }

}

export default CustomForm


// https://gitlab.com/StorWork/Storwork-Create/blob/dev/docs/expertSystem.md

// buildForm = () => {
//   console.log(this.inputs);
//   if (this.inputs == {}) return {};
//   const built_form = {};
//   Object.keys(this.inputs).map(key => {
//     const input = this.inputs[key];
//     if (input.value == 'on') {
//       built_form[key] = input.checked;
//     } else {
//       built_form[key] = input.value;
//     }
//   });
//   return built_form;
// }
