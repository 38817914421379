import baseAuth from 'services/baseAuth'
import {JWT, GlobalStorage} from 'storwork-stores'

const fetchUsers = callback => {
  const query = `
        query GetUsers{
            myOrganization {
                users {
                    full_name
                    id
                }
            }
        }
    `
  const variables = {}
  baseAuth(query, variables, callback)
}

export const userStorageWrite = (callback = () => { }) => {
  fetchUsers(data => {
    if (data.status !== 200) {
      console.log(data)
      console.warn('Be careful! Get users responded with: ' + data.status)
    } else {
      if (typeof Storage !== 'undefined') {
        GlobalStorage.setItem(
          'group_users',
          JSON.stringify(data.data.myOrganization.users)
        )
        callback()
      } else {
        console.warn(
          'Local storage is not supported. In future versions of app add polyfill!'
        )
      }
    }
  })
}

export const changeOrganization = (organizationId) => {
  const query = `
    {
        setActiveOrganization(id: ${organizationId})
    }
    `
  const variables = {}
  baseAuth(query, variables, (data) => {
    JWT.keep(data.data.setActiveOrganization)
    GlobalStorage.refreshCache(data => {
      window.location = '/'
    })
  })
}
