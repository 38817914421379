import {JWT} from 'storwork-stores'
import baseAuth from './baseAuth'

export const getProcesses = (callback) => {
  const variables = {
  }

  let query = `query getProcess {
                  repository {
                      expertActions {
                          data {
                            id
                            name
                            link
                            expertProcesses{
                                id
                                status
                                result
                                context
                            }
                          }
                      }
                  }
              }`
  baseAuth(query, variables, callback)
}

export const deleteProcess = (id, callback) => {
  const query = `mutation deleteProcess {
          deleteExpertProcess(id: ${id})
  }`
  const variables = {}
  baseAuth(query, variables, callback)
}
