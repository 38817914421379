import Ajax from '../Ajax';

export const getExpertAction = (link, callback) => {

    const query = `query getExpertAction($link:String!) {
      getExpertAction(link:$link) {
            name,
            description,
            json_schema,
            ui_schema,
            link,
            validation,
            id
        }
    }`;

    const variables = {
        link: link
    };

    Ajax(query, variables, callback);

};

export const registerExpertProcess = (link, input, callback) => {

    const query = `mutation registerExpertProcess($link:String!,$input:String) {
      registerExpertProcess(link:$link,input:$input) {
        expertActionJsonSchema
        expertActionDescription
        expertActionValidation
        expertActionUiSchema
        expertActionName
        status
        result
        token
        actions
      }
    }`;

    const variables = {
        link: link,
        input: input
    };

    Ajax(query, variables, callback);

};


export const changeExpertProcess = (token, input = "", callback) => {

    const query = `mutation changeExpertProcess($token:String!,$input:String) {
      changeExpertProcess(token:$token,input:$input) {
        token,
        status,
        result,
        context,
        expertActionJsonSchema
        expertActionDescription
        expertActionValidation
        expertActionUiSchema
        expertActionName
        actions
      }
    }`;

    const variables = {
        token: token,
        input: input
    };

    Ajax(query, variables, callback);

};

export const backExpertProcess = (token, input = "", action_id = 0, callback) => {

    const query = `mutation BackExpertProcess($token:String!, $input:String, $action_id:Int) {
      BackExpertProcess(token:$token, input:$input, action_id:$action_id) {
        token,
        status,
        result,
        context,
        expertActionJsonSchema
        expertActionDescription
        expertActionUiSchema
        expertActionValidation
        expertActionName
        actions
      }
    }`;

    const variables = {
        token: token,
        input: input,
        action_id: action_id
    };

    Ajax(query, variables, callback);

};

// https://gitlab.com/StorWork/Storwork-Create/blob/dev/docs/expertSystem.md
