import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Main from './Main/Main'
import Login from './Login/Login'
import Logout from './Login/Logout'
import ActionsList from './Actions/ActionsList'
import ActionCreate from './Actions/ActionCreate'
import ActionCreate2 from './Actions/ActionCreate2'
import ActionEdit from './Actions/ActionEdit'
import ActionGenerate from './Actions/ActionGenerate'
import ActionGenerate2 from './Actions/ActionGenerate2'
import ProcessList from './Process/ProcessList'
import CustomForm from 'components/CustomForm';
import BusinessForm from 'components/BusinessForm';
import BusinessForm2 from 'components/BusinessForm2';
import StepByStepForm from 'components/StepByStepForm';
import {JWT, GlobalStorage} from 'storwork-stores'
import Navbar from "./Navbar/Navbar";
import moment from 'moment'
import localization from 'moment/locale/pl'
import {StorworkInit} from 'storwork-stores'

moment.locale('pl', localization)

const Redirector = () => {
  return JWT.get() ? <Redirect to='/list-action' /> : <Redirect to='/login' />
}

class App extends Component {
  state = {
    gsInit: false
  }

  checkIsLogged = () => {
    GlobalStorage.initStorage([
      'group_users', 'group_taxonomies', 'strategies', 'organization_settings', 'JWT_TOKEN'
    ]).then(() => {
      this.setState({
        gsInit: true
      })
    })
  }

  componentWillMount () {
    this.checkIsLogged()
  }

  render () {

    return (
      <StorworkInit loggedIn={
        <div className='App'>
          <Navbar/>
          <Route path='/' exact component={Redirector}/>
          {/*<Route exact path='/' component={Main}/>*/}
          <Route exact path='/processes' component={ProcessList}/>
          <Route exact path='/processes/action/:id' component={ProcessList}/>
          <Route exact path='/list-action' component={ActionsList}/>
          <Route exact path='/create-action' component={ActionCreate}/>
          <Route exact path='/create-action2' component={ActionCreate2}/>
          <Route exact path='/edit-action/:id' component={ActionEdit}/>
          <Route exact path='/generate-action' component={ActionGenerate}/>
          <Route exact path='/generate-action2/:id?' component={ActionGenerate2}/>
          <Route exact path='/logout' component={Logout}/>
        </div>
      } loggedOut={
        <div className='App'>
          <Route exact path='/' component={Redirector}/>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/embed/:link/Custom' component={CustomForm}/>
          <Route exact path='/embed/:link/Business' component={BusinessForm}/>
          <Route exact path='/embed/:link/Business2' component={BusinessForm2}/>
          <Route exact path='/embed/:link/StepByStep' component={StepByStepForm}/>
        </div>
      }/>
    )
  }
}

export default App
