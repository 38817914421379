import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import login from '../services/login'
import Input from './Input'
import PasswordInput from './PasswordInput.js'
import validateLength from '../services/validators/validateLength'
import validateMail from '../services/validators/validateMail'
import {JWT, GlobalStorage} from 'storwork-stores'

class Login extends Component {
  state = {
    email: '',
    password: '',
    status: false,
    error: ''
  }
  mailOnChange = event => {
    let attributeToChangeValue = event.target.value
    this.setState({
      [event.target.name]: attributeToChangeValue,
      [event.target.name + '_validate']: validateMail(attributeToChangeValue)
    })
  }
  onPassChange = event => {
    let pass = event.target.value
    this.setState({
      password: pass,
      // should be pass, 8 but for deployment reasons we changed it
      password_validate: validateLength(pass, 7),
      password_confirmation: pass
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    login(this)
  }

  render () {
    const {email_validate, password_validate} = this.state
    if (this.state.status) {
      if (GlobalStorage.getItem('invitationToken')) {
        return <Redirect to={'/invitation/' + GlobalStorage.getItem('invitationToken')}/>
      }
      window.location.href = '/list-action';
    }

    return (
      <div className={'bootstrap'} style={{width:'280px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
        <form>
          <Input
            label='Adres e-mail'
            wrongInputMessage='Adres e-mail jest nieprawidłowy'
            name='email'
            type='text'
            length={this.state.email.length}
            validated={email_validate}
            onChange={this.mailOnChange}
            width='280'
            autoFocus
          />
          <PasswordInput
            label='Hasło'
            wrongInputMessage='Hasło jest nieprawidłowe'
            name='password'
            length={this.state.password.length}
            validated={password_validate}
            onChange={this.onPassChange}
            width='280'
          />
          <div>
            <button className={'bootstrap btn btn-primary btn-sm btn-block'}
                    onClick={this.handleSubmit}
            >
              Zaloguj
            </button>
            <br/>
            <br/>
            <p>
              Zapomniałeś hasła?
              <Link to='/reset'>
                &nbsp;Kliknij tutaj.
              </Link>
            </p>
          </div>
        </form>

        <div>
          <p>
            Nie masz jeszcze konta?
            <Link to='/signup'>
              &nbsp; Zarejestruj się!
            </Link>
          </p>
        </div>
      </div>

    )
  }
}

export default Login
