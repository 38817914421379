import React from 'react'
import {Redirect} from 'react-router-dom'
import {JWT, GlobalStorage} from 'storwork-stores'

export default (props) => {
  JWT.forget()
  window.location.assign('/login')
  // GlobalStorage.removeItem('group_taxonomies')
  // GlobalStorage.removeItem('group_users')
  // GlobalStorage.removeItem('JWT_TOKEN')
  // return <Redirect to='/login' />
}
