export const validateMail = mail => {
  let isValid;
  let correctMail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (correctMail.test(mail)) {
    isValid = true;
  } else if (correctMail.test(mail) === false) {
    isValid = false;
  }
  return isValid;
};

export default validateMail;
