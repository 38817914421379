import React from 'react'
import { Link } from 'react-router-dom'
import {JWT} from 'storwork-stores'
import {exportToCsv} from "../services/exportToCsv";
import {changeOrganization} from "services/organization";

export default class Navbar extends React.Component {
  state = {
    open: false,
    showMenu: false
  }

  render () {

    let user = {};
    let organization = {};
    let organizations = [];
    let isLoggedIn = false;
    let permission = '';

    if(JWT.get() && JWT.remember()) {
      const token = JWT.remember();
      user = token.claim.user;
      organization = token.claim.organization;
      organizations = token.claim.organizations;
      permission = token.claim.permission;
      isLoggedIn = true;
    }

    return <nav className="flex items-center justify-between flex-wrap bg-teal p-6">
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <span className="font-semibold text-xl tracking-tight">Forms</span>
      </div>
      <div className="block lg:hidden" onClick={() => this.setState({
        open: !this.state.open
      })}>
        <button className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className={"w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto " + (!this.state.open ? 'sm:hidden' : 'sm:block')}>
        <div className="text-sm lg:flex-grow">
          <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4">
            Lista formularzy
          </Link>
          <Link to="/processes" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4">
            Procesy
          </Link>
          <Link to="/create-action" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4">
            Dodaj akcję
          </Link>
          {/*<Link to="/generate-action2" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4">*/}
            {/*Generator*/}
          {/*</Link>*/}
        </div>
        {/*<div className={'text-sm float-right'}>*/}
          {/*<Link to="/logout" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4">*/}
            {/*Wyloguj*/}
          {/*</Link>*/}
        {/*</div>*/}
        <div>
          <div style={{position: 'absolute', right: '108px', marginTop: '-8px', zIndex: 1}}>
            <div className="relative mb-24" >
              <div className="text-sm lg:flex-grow text-teal-lighter">
                <div style={{cursor: 'pointer'}} onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                  {user.full_name}
                  <span style={{padding: '0 10px 0'}}><i className="fas fa-chevron-down"></i></span>
                </div>

                {/*<svg class="h-4" version="1.1" viewBox="0 0 129 129"  enable-background="new 0 0 129 129">*/}
                  {/*<g>*/}
                    {/*<path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/>*/}
                  {/*</g>*/}
                {/*</svg>*/}
              </div>
              <div className="rounded shadow-md mt-2 absolute mt-6 pin-t pin-l min-w-full" style={{width: '195px', display: (this.state.showMenu ? 'block' : 'none')}}>
                <ul className="list-reset bg-white border">
                  {
                    organizations.map(org => {
                      return <li key={org.id} className={organization.id === org.id ? "active" : ""}>
                        <a key={org.id} onClick={() => {changeOrganization(org.id)}} className={'px-4 py-2 block text-black hover:bg-grey-light'} style={{cursor: 'pointer'}}>
                          {org.name}
                        </a>
                      </li>
                    })
                  }
                  <li><hr className="border-t mx-2 border-grey-light" /></li>
                  <li>
                    <Link to="/logout" className="px-4 py-2 block text-black hover:bg-grey-light">
                      Wyloguj
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*{this.props.pathname === '/processes' &&*/}
          {/*<div>*/}
            {/*<a className="cursor-pointer inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal hover:bg-white mt-4 lg:mt-0">*/}
              {/*Eksport*/}
            {/*</a>*/}
          {/*</div>*/}
        {/*}*/}
      </div>
    </nav>
  }
}
