import baseAuth from './baseAuth';


export const getActions = (vars = {}, callback) => {
  const variables = {
    filter: vars.filter ? vars.filter : []
  };
  const query = `query getActions($filter:[String]) {
    repository {
      expertActions(filter:$filter) {
        data {
          id
          name
          description
          type
          link
          extras
          validation
          logic
          json_schema
          ui_schema
        }
      }
    }
  }`;

  baseAuth(query, variables, callback);
};

export const getAction = (id, callback) => {
  getActions({filter:["ids:" + id]}, callback);
};

/**
 *
 * @param data
 * @param callback
 */
export const createAction = (data, callback) => {
  const query = `mutation createExpertAction($input:ExpertActionInputType!) {
    createExpertAction(input:$input)
  }`;

  baseAuth(query, {input: data}, callback);
};

/**
 *
 * @param id
 * @param data
 * @param callback
 */
export const updateAction = (id, data, callback) => {
  const query = `mutation updateExpertAction($id:Int!,$input:ExpertActionInputType!) {
    updateExpertAction(id:$id,input:$input)
  }`;

  baseAuth(query, {id: id, input: data}, callback);
};

/**
 *
 * @param id
 * @param callback
 */
export const deleteAction = (id, callback) => {
  const query = `mutation deleteExpertAction($id:Int!) {
    deleteExpertAction(id:$id)
  }`;

  baseAuth(query, {id: id}, callback);
};
