import React, { Component } from 'react'
import Navbar from '../Navbar/Navbar'
import {Link} from 'react-router-dom'
import {JWT} from 'storwork-stores'
import {changeOrganization} from 'services/organization'

class Main extends Component {
  render () {
    const token = JWT.remember()
    const organizations = token.claim.organizations
    const activeOrganization = token.claim.organization

    return (
      <div>
        {/*<Navbar />*/}
        <div className='pull-left col-sm-2 text-left'>
          <ul className='list-unstyled'>
            <li>
              <hr className='dropdown-divider' />
              <small>Moje organizacje</small>
              <hr className='dropdown-divider' />
            </li>
            {
              organizations.map(organization => {
                if (activeOrganization.id === organization.id) {
                  return <li key={organization.id}>
                    <strong>{organization.name}</strong>
                  </li>
                } else {
                  return <li key={organization.id} onClick={() => {
                    changeOrganization(organization.id)
                  }}>
                    <a href='#'>{organization.name}</a>
                  </li>
                }
              })
            }
            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link to={`/app/list-action`}>Lista akcji</Link>
            </li>
            <li>
              <Link to={`/app/create-action`}>Dodaj</Link>
            </li>
            <li>
              <Link to={`/app/generate-action2`}>Wygeneruj</Link>
            </li>
            <li>
              <Link to={`/app/list-process`}>Lista procesów</Link>
            </li>
            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link to='/logout'>Wyloguj</Link>
            </li>
          </ul>
        </div>
      </div>

    )
  }
}

export default Main
