import React,{Component} from 'react';


class FormButtons extends Component {

    render() {
        return  <div>
                    <button type="button" id="backBtn" className={"btn btn-info"} style={{float: "left"}} onClick={this.props.forForm.previousField}>Wstecz</button>
                    <button type="submit" id="submitBtn" className={"btn btn-default"} style={{float: "right"}}>Wyslij</button>
                </div>;
    }
}

export default FormButtons;