import React, { Component } from 'react'
import _ from 'lodash'
import Form from 'react-jsonschema-form'
import {observer} from 'mobx-react'
import * as C from 'storwork-components'

function slugify(text)
{
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

const FormBuilder = observer(class _FormBuilder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeField: 'new',
      fieldToChange: '',
      schema: props.value ? JSON.parse(props.value) : {
        type: 'object',
        properties: {}
      }
    }
  }
  componentWillReceiveProps (props) {
    // const newState = {
    //   activeField: 'new',
    //   fieldToChange: '',
    //   schema: props.value ? JSON.parse(props.value) : {
    //     type: 'object',
    //     properties: {}
    //   }
    // }
    // if (JSON.stringify(newState.schema) === JSON.stringify(this.props.schema)) return
    this.setState({
      schema: props.value ? JSON.parse(props.value) : {
        type: 'object',
        properties: {}
      }
    })
  }
  onClick = e => {
    if (!e) return
    this.setState({
      activeField: e,
      fieldToChange: e
    })
  }
  CustomFieldTemplate = props => {
    const {id, classNames, label, help, required, description, errors, children} = props
    const classNamesWithActive = this.state.activeField === props.help._owner.key ? classNames + ' active' : classNames + ' pointer'
    const excludeRoot = id === 'root' ? '' : classNamesWithActive
    return (
      <div className={excludeRoot} onClick={() => this.onClick(props.help._owner.key)}>
        <label htmlFor={id}>{label}{required ? '*' : null}</label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    )
  }
  handleChange = (path) => (value) => {
    const newValue = value.target ? value.target.value : value
    this.setState(state => {
      _.set(state, path, newValue)
      return state
    }, this.onChange)
  }
  addQuestion = (callback = this.onChange) => {
    const title = 'Nowe pole ' + Object.keys(this.state.schema.properties).length
    this.setState(state => {
      state.schema.properties[slugify(title)] = {
        swType: 'text',
        type: 'string',
        title: title
      }
      return state
    }, () => {
      callback(slugify(title))
    })
  }
  newEnum = name => () => {
    this.setState(state => {
      state.schema.properties[name].enum.push('')
      state.schema.properties[name].enumNames.push('')
      return state
    }, this.onChange)
  }
  deleteEnum = (name, ind) => () => {
    this.setState(state => {
      state.schema.properties[name].enum.splice(ind, 1)
      state.schema.properties[name].enumNames.splice(ind, 1)
      return state
    }, this.onChange)
  }
  changeType = name => e => {
    const value = e.target ? e.target.value : e
    this.setState(state => {
      state.schema.properties[name].swType = value
      if (value === 'text') {
        state.schema.properties[name].type = 'string'
        delete state.schema.properties[name].enum
        delete state.schema.properties[name].enumNames
      }
      if (value === 'number') {
        state.schema.properties[name].type = 'integer'
        delete state.schema.properties[name].enum
        delete state.schema.properties[name].enumNames
      }
      if (value === 'enum') {
        state.schema.properties[name].type = 'string'
        state.schema.properties[name].enum = []
        state.schema.properties[name].enumNames = []
      }
      return state
    }, this.onChange)
  }
  addCustomQuestion = value => {
    this.addQuestion((name) => {
      this.changeType(name)(value)
    })
  }
  deleteField = e => {
    this.setState(state => {
      const {activeField} = this.state
      delete state.schema.properties[activeField]
      state.activeField = 'new'
      return state
    }, this.onChange)
  }
  moveField = () => {
    this.setState(state => {
      const {fieldToChange, activeField} = this.state
      state.schema.properties[fieldToChange] = state.schema.properties[activeField]
      delete state.schema.properties[activeField]
      state.activeField = fieldToChange
      return state
    }, this.onChange)
  }
  onChange = () => {
    this.props.onChange(JSON.stringify(this.state.schema))
  }
  render () {
    const {schema, activeField} = this.state
    const {properties} = schema
    const activeElement = properties[activeField]
    const MyForm = props => <Form schema={this.state.schema} FieldTemplate={this.CustomFieldTemplate} >
      <span />
    </Form>
    return <C.Box padding='15px'><div className='columns'>

        {this.state.activeField === 'new' ? <div className='column is-3' style={{float: 'right', width: '40%'}}>
          <div className='pointer btn btn-primary inline' style={{margin: '0 12px 0 0'}} onClick={() => this.addCustomQuestion('text')}>Nowe pole tekstowe</div>
          <div className='pointer btn btn-primary inline' style={{margin: '0 12px 0 0'}} onClick={() => this.addCustomQuestion('number')}>Nowe pole numeryczne</div>
          <div className='pointer btn btn-primary inline' style={{margin: '0 12px 0 0'}} onClick={() => this.addCustomQuestion('enum')}>Nowe pole wyboru</div>
        </div> : <div className='column is-3' style={{float: 'right', width: '40%'}}>
          <div>
            <div className='form-group'>
              Tytuł pola:
              <input type='text' className='form-control' value={activeElement ? activeElement.title : ''} onChange={this.handleChange('schema.properties.' + activeField + '.title')} placeholder='Wpisz label...' />
            </div>
          </div>
          <div className='form-group'>
            <div className='control'>
              slug:
              <input type='text' className='form-control' value={this.state.fieldToChange} onChange={this.handleChange('fieldToChange')} onBlur={this.moveField} placeholder='Wpisz key...' />
            </div>
          </div>
          <div className='form-group'>
            <div className='control'>
              typ:<br />
              {/*<div className='select is-medium transparent is-fullwidth '>*/}
                <select className={'form-control'} value={activeElement ? activeElement.swType : ''} onChange={this.changeType(activeField)}>
                  <option value='text'>tekst</option>
                  <option value='number'>numer</option>
                  <option value='enum'>radio</option>
                </select>
              {/*</div>*/}
            </div>
          </div>
          <span className='btn btn-danger' onClick={this.deleteField}>Usuń pole</span>
          <div className={'btn btn-primary inline pull-right'} onClick={() => this.onClick('new')}>
            Nowe pole <i className='fal fa-plus' />
          </div>
          {activeElement && activeElement.swType === 'enum' && <React.Fragment>
            <hr />
            Lista wartości: <br />
            {activeElement && activeElement.enum.map((__, ind) => <div className='columns'>
              <div className='row form-group'>
                  <div className='col-sm-5'>
                    <input placeholder='Wartość...' className='form-control' value={activeElement.enum[ind]} onChange={this.handleChange('schema.properties.' + activeField + '.enum.' + ind)} />
                  </div>
                  <div className='col-sm-5'>
                    <input placeholder='Opis wartości...' className='form-control' value={activeElement.enumNames[ind]} onChange={this.handleChange('schema.properties.' + activeField + '.enumNames.' + ind)} />
                  </div>
                <div className={'col-sm-2 text-center'}>
                  <button className={'btn btn-danger'} onClick={this.deleteEnum(activeField, ind)} style={{cursor: 'pointer'}}>
                    Usuń wartość <i className='fal fa-trash-alt' />
                  </button>
                </div>
              </div>
            </div>
            )}
            <span className='btn btn-success' onClick={this.newEnum(activeField)}>Nowa wartość</span>
          </React.Fragment>
          }
        </div>}
      <div className='column' style={{float: 'left', width: '58%'}}>
        <hr/>
        <MyForm />
        <hr/>
      </div>
    </div>
    </C.Box>
  }
})

export default FormBuilder
