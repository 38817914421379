import React,{Component} from 'react';
import Form from "react-jsonschema-form";
import jQuery from 'jquery';
import * as Scroll from 'react-scroll';
import _ from 'lodash';
import queryString from 'query-string'

import logo from '../Img/logo.png'

import {registerProcess, toStepConverter, updateProcess, getProcess} from "../services/core";
import Toast from "./Toast";

import styles from "./CustomForm.css";
import styled from 'styled-components';
// import 'bulma/css/bulma.css';

const Wrapper = styled.div`
  margin 100px auto;
  padding: 50px;
  border: 1px solid #487eb0;
  border-radius: 3px;
  max-width: 50%;
  color: #487eb0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
`;

const Press = styled.span`
  font-size: 17px;
  color: #718093;
  margin-right: 5px;
`
const Enter = styled.button`
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  background: #487eb0;
  color: #fff;
  padding: 3px 6px;
`

class CustomForm extends Component {
    state = {
        jsonSchema: null,
        uiSchema: null,
        paddingTop: 100,
        scrollPosition: 0,
        values: [],
        showEnterButton: false,
        width: window.innerWidth,
        formData: queryString.parse(this.props.location.search)
    };

    questions = {}
    inputs = {}
    indicies = []

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
      window.addEventListener("beforeunload", this.onUnload);
      setTimeout(() => this.forceUpdate(), 300);
      let link = this.props.match.params.link;
      if(link) {
          getProcess(link, this);
      }
      // setTimeout(() => this.indicies[0]&& this.scrollToElement(this.indicies[0]), 1000);
    }

    componentWillReceiveProps(nextProps) {
      window.addEventListener('resize', this.handleWindowSizeChange);
      window.addEventListener("beforeunload", this.onUnload);
      let link = nextProps.match.params.link;
      if(link) {
          getProcess(link, this);
      }
      // setTimeout(() => this.indicies[0]&& this.scrollToElement(this.indicies[0]), 1000);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
      window.removeEventListener("beforeunload", this.onUnload);
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };

    onUnload = (event) => {
      const message = "Po odświeżeniu strony stracisz dane fomularza";
      event.returnValue = "Po odświeżeniu strony stracisz dane fomularza";
      return message;
    }

    handleScroll = (e) => {
      this.setState({scrollPosition: e.target.scrollTop});
    }

    checkHighlighted = () => {
      console.log(this.indicies);
      console.log(this.questions);

      for (let i = 0; i < this.indicies.length; i++) {
        const key = this.indicies[i];
        if (!this.questions[key] || this.questions[key].offsetTop <= this.state.scrollPosition -100) {
          continue;
        }
        // if(this.inputs[key])
        //     this.inputs[key].focus();
        return i;
      }
      return this.indicies.length - 1;
    }

    handleClickOnEnterButton = (event) => {
        event.preventDefault();
        const element = this.checkHighlighted() + 1;

        if (this.indicies.length === element ) {
            jQuery('#submitBtn').click();
            this.scrollToError();
        } else {
            this.scrollToElement(this.indicies[element]);
        }
    }

    handleKeydown = (event) => {
      if (event.keyCode === 13) { // If ENTER or Tab
        event.preventDefault();
        const element = this.checkHighlighted() + 1;
        if (this.indicies.length === element ) {
            jQuery('#submitBtn').click();
            this.scrollToError();
        } else {
            this.scrollToElement(this.indicies[element]);
        }
      }
      if(event.keyCode === 9) {
          event.preventDefault();
          const element = this.checkHighlighted() + 1;
          if (this.indicies.length === element) {
              this.scrollToElement(this.indicies[0]);
          } else {
              this.scrollToElement(this.indicies[element]);
          }
      }
    }

    scrollToError = () => {
      setTimeout(() => {
        if (jQuery(".errorScrollTo")[0]){
            let errorDiv = jQuery(".errorScrollTo")[0];
            let attr = errorDiv.getAttribute("datafield");
            attr && this.scrollToElement(attr);
        }
      }, 300);
    }

    scrollToElement = (key) => {
        if (this.indicies[this.checkHighlighted()] == key) return;
            Scroll.animateScroll.scrollTo(
                this.questions[key].offsetTop - this.state.paddingTop,
                {
                    containerId: 'container',
                    duration: 300,
                    delay: 0
                }
            );
    }

    onSubmit = ({formData}) => {
      this.setState(state => {
        state.formData = _.merge(state.formData, formData);
        state.scrollPosition = 0;
        return state;
      },() => {
        let link = this.props.match.params.link;
        if (this.state.status != "finish" && this.state.status != "inProgress") {
          registerProcess(link, JSON.stringify(this.state.formData), this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
           })
        }else if(this.state.status != "finish" && this.state.status == "inProgress"){
          updateProcess(this.state.token, this.state.formData, this, false, (callback) => {
            // this.indicies[0] && this.scrollToElement(this.indicies[0])
          })
        }
      })
    };

    customFieldTemplate = (props) => {
        const {id, classNames, label, help, required, description, errors, children, safeRenderCompletion, rawErrors=[]} = props;

        if (id != 'root' && !_.find(this.indicies, el => el == id) && id != undefined) {
          this.indicies.push(id);
        }

        const highlighted_one = this.indicies[this.checkHighlighted()];
        // console.log(highlighted_one);

        return (
          <div
            className={"questions"}
            ref={div => {if (id != 'root') this.questions[id] = div;}}
            style={highlighted_one == id || id == 'root' ? {} : {opacity: 0.3}}
            onClick={(e) => {e.stopPropagation(); if (id != 'root') this.scrollToElement(id)}}
          >
              {id == "root"? null:
                <label
                  className={classNames}
                  style={{fontWeight: "500", fontSize: "25px", color: "#487eb0", marginBottom: 40}}
                  htmlFor={id}
                >
                  {label}{required ? "*" : null}
                </label>
              }
              {children}
              {rawErrors.map(error => <div key={id} className={"errorScrollTo"} datafield={id} style={{color: "red", fontsize: 20, fontWeight:500, marginTop: "15px", marginLeft: "140px"}}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
              </div>)}
              {help}
          </div>
        );
    }

    _onChange = ({ target: { value } }, props) => {
      return props.onChange(value === "" ? "" : value);
    };

   asNumber = (value) => {
      const n = Number(value);
      const valid = typeof n === "number" && !Number.isNaN(n);
    return valid ? n : value;
    }

    NumberField = (props) => {
      const { StringField } = props.registry.fields;

      return (
        <div>
          <StringField
            {...props}
            onChange={value => props.onChange(this.asNumber(value))}
          />
        </div>
      );
    }

    customString = (props) => {

      const valueFromPlaceholder = props.placeholder.slice(11);
      const type = props.options.inputType ? props.options.inputType : "text";
      return (
        <div>
          <input
            type={type}
            className={"customInput"}
            ref={input => {if (props.id != 'root') this.inputs[props.id] = input}}
            value={props.value == null ? "" : props.value}
            required={props.required}
            onChange={(e) => this._onChange(e, props)}
            onKeyDown={this.handleKeydown}
            placeholder={props.placeholder}
            />
          {
            props.required ?
              props.value || props.value === 0 ?
                  <div style={{marginTop: "10px", position: "absolute"}} className={"animated fadeInUp"}>
                    <Press>naciśnij</Press> <Enter onClick={this.handleClickOnEnterButton}>Enter</Enter>
                  </div>
                : null
              : <div style={{marginTop: "10px", position: "absolute"}} className={"animated fadeInUp"}>
                  <Press>naciśnij</Press> <Enter onClick={this.handleClickOnEnterButton}>Enter</Enter>
                </div>
          }
        </div>
      );
  }

    customCheckbox = (props) => {
      return (
        <div>
          <label class="switch">
            <input
              type="checkbox"
              ref={input => {if (props.id != 'root') this.inputs[props.id] = input}}
              required={props.required}
              onChange={event => props.onChange(event.target.checked)}
              onKeyDown={this.handleKeydown}
              checked={typeof props.value === "undefined" ? false : props.value}
            />
          <span class="slider round"></span>
        </label>
            {
              props.required ?
                props.value ?
                    <div style={{marginTop: "10px", position: "absolute"}} className={"animated fadeInUp"}>
                      <Press>naciśnij</Press> <Enter onClick={this.handleClickOnEnterButton}>Enter</Enter>
                    </div>
                  : null
                : <div style={{marginTop: "10px", position: "absolute"}} className={"animated fadeInUp"}>
                    <Press>naciśnij</Press> <Enter onClick={this.handleClickOnEnterButton}>Enter</Enter>
                  </div>
            }
        </div>
      );
  }

  customRadio = (props) => {
    const {options, value, required, disabled, readonly, autofocus, onChange} = props;
    const name = Math.random().toString();
    const { enumOptions, inline } = options;
    return (
      <div style={{color: "#487eb0"}}>
        {enumOptions.map((option, i) => {
           const checked = option.value === value;
           const disabledCls = disabled || readonly ? "disabled" : "";

           const radio = (
            <div  className={checked? "customRadioContainerChecked animated fadeIn": "customRadioContainer" }>
              <input
                 className={"customRadio"}
                 type="radio"
                 checked={checked}
                 name={name}
                 required={required}
                 value={option.value}
                 onChange={event => {
                   const element = this.checkHighlighted() + 1;

                   if( element != this.indicies.length ){
                     onChange(option.value)
                     setTimeout(() => this.handleClickOnEnterButton(event), 300)
                   }else{
                     onChange(option.value);
                     setTimeout(() => this.handleClickOnEnterButton(event), 300)
                     // this.setState({showEnterButton: true}, () => {
                     //
                     // })
                   }

                 }}
                 onKeyDown={this.handleKeydown}
                 />
               <span className={"customRadioSpan"}>{option.label}</span>
            </div>
          );
              return  (
                <label key={i}>
                  {radio}
                </label>
              )
        })}
        {
          this.state.showEnterButton &&
            <div style={{marginTop: "10px", position: "absolute"}} className={"animated fadeInUp"}>
              <Press>naciśnij</Press> <Enter onClick={this.handleClickOnEnterButton}>Enter</Enter>
            </div>
        }
      </div>
    )
  }

  changeForm = ({formData}) => {
      this.setState(state => {
        state.formData = _.merge(state.formData, formData);
        return state;
      })
  }

  TitleField = (props) => {
    // return null;
    const { id, title, required } = props;
    const legend = required ? title + "*" : title;
    return <legend style={{fontWeight: "800", color: "#4975AB"}} id={id}>{legend}</legend>;
  }

  DescriptionField = (props) => {
    // return null;
    const {id, description} = props;
    return <div id={id} style={{ background: "#03b0ee", color: "#fff", padding:"15px"}}>{description}</div>;
  }

  transformErrors = (errors) => {
    return errors.map(error => {
      if (error.name === "required") {
        error.message = "To pole jest wymagane"
      }
      return error;
    });
  }

  steps = () => {
    let array = JSON.parse(this.state.description);
    let steps = array.map( (el, i) => {
      return(
        <div key={i} className={el.active == "yes" ? "step-item is-completed is-success" : "step-item" }>
          <div className="step-marker">
            <span className="icon">
              <i className="fa fa-check"></i>
            </span>
          </div>
          <div className="step-details">
            <p className="step-title" style={{color:"rgb(72, 126, 176)"}}>{el.titleStep}</p>
            <p style={{color:"rgb(72, 126, 176)"}}>{el.subtitleStep}</p>
          </div>
        </div>
      )
    })
    return steps;
  }

    render() {

        const { width } = this.state;
        const isMobile = width <= 500;

        const widgets = {
          CheckboxWidget: this.customCheckbox,
          BaseInput: this.customString,
          RadioWidget: this.customRadio
        }

        if (!this.state.jsonSchema) return null;

        if (this.state.status === 'finish')
            return (<Wrapper><Toast text={this.state.result}/></Wrapper>);
// , paddingTop: "50px"
          return (
            <div style={{overflow:"hidden", margin: "0 auto",maxWidth: 600,fontFamily: "'Raleway', sans-serif"}}>
              <div
                  className={"scroll1"}
                  id='container'
                  style={{marginRight:"-16px", height:this.state.paddingTop*5, overflow:'auto', position: 'relative',
                    paddingLeft: this.state.paddingTop/4,
                    paddingRight: this.state.paddingTop/4, boxSizing:'border-box'}}
                  onScroll={this.handleScroll}
                >

              <img src={'/logo.png'} style={{width:'100%', padding: '0 30%', marginBottom: 20, marginTop: 60}} />
              <div className="steps">
                {(this.state.description && this.state.description != "NULL") && this.steps()}
              </div>


                  <Form
                    schema={this.state.jsonSchema}
                    uiSchema={this.state.uiSchema}
                    FieldTemplate={this.customFieldTemplate}
                    onChange={this.changeForm}
                    widgets={widgets}
                    fields={{
                      NumberField: this.NumberField,
                      TitleField: this.TitleField,
                      DescriptionField: this.DescriptionField
                    }}
                    formContext={{scroll: this.state.scrollPosition}}
                    formData={this.state.formData}
                    onSubmit={this.onSubmit}
                    showErrorList={false}
                    id="main-form"
                    noHtml5Validate
                    transformErrors={this.transformErrors}
                  >

                    <button style={{border: "none", borderRadius: "3px", background: "#487eb0", color: "#fff", padding: "3px 6px", display: "none"}} type="submit" id="submitBtn" onClick={this.scrollToError}>Wyslij</button>
                    <div style={{height: "120px"}}/>
                  </Form>
              </div>
              </div>
          );

        // }



    }

}

export default CustomForm


// https://gitlab.com/StorWork/Storwork-Create/blob/dev/docs/expertSystem.md

// buildForm = () => {
//   console.log(this.inputs);
//   if (this.inputs == {}) return {};
//   const built_form = {};
//   Object.keys(this.inputs).map(key => {
//     const input = this.inputs[key];
//     if (input.value == 'on') {
//       built_form[key] = input.checked;
//     } else {
//       built_form[key] = input.value;
//     }
//   });
//   return built_form;
// }

// <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
//   <img src={logo} alt={"logo"} style={{marginBottom: "20px", width: "50%"}}/>
// </div>
//
