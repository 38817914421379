import React from 'react'
import {exportToCsv} from "../services/exportToCsv";

export default class FilterBar extends React.Component {
  state = {
    open: false
  }

  handleDownloadCSV() {
    exportToCsv('dane.csv', this.props.csvData)
  }

  sort = (str, action) => {
    const sortParams = this.props.store.getFilter('order');
    let defaultOrder = 'asc';
    if(sortParams) {
      const explode = sortParams.split('%20');
      console.log(explode);
      if(explode[1] === 'asc') {
        defaultOrder = 'desc';
      }

    }
    console.log(defaultOrder);
    this.props.store.setFilter('order', 'created_at.timestamp%20' + defaultOrder);
  };


  render () {
    const store = this.props.store;

    const sortOptions = [
      {
        value: 'actions',
        label: 'Ostatnio wypełnione',
        sort: 'created_at.timestamp asc'
      },
      {
        value: 'processes',
        label: 'Ostatnio stworzone',
        sort: 'created_at.timestamp asc'
      }
    ]

    return <div className='w-full clearfix leading-loose text-base h-8'>
      <div className='float-left'>
        <input className='bg-white rounded border border-grey h-6 pl-2' placeholder='Szukaj...' onChange={(e) => store.setFilter('fulltext', e.target.value)} value={store.getFilter('fulltext') ? store.getFilter('fulltext') : ''}/>
      </div>
      <div className='float-right relative'>
        <div onClick={() => {
          this.setState({
            open: !this.state.open
          })
        }}>
          Sortowanie po <b className='cursor-pointer'>Ostatnio wypełnione</b>
        </div>
        {this.state.open && <div className='absolute text-right bg-white pl-4 pr-2 rounded border border-grey' style={{top: '2rem', right: 0}}>
          {sortOptions.map((el, key) => <div key={key} className='cursor-pointer' onClick={() => this.sort(el.sort)}>
            {el.label}
          </div>)}
        </div>}
      </div>
      {window.location.pathname.indexOf('/processes') !== -1 &&
        <div className='pull-right' style={{padding: '0 16px 0 16px'}} onClick={(e) => {this.handleDownloadCSV()}}>
          <a className="cursor-pointer inline-block text-sm px-4 py-2 leading-none border rounded text-teal border-teal hover:text-white hover:bg-teal lg:mt-0">
            Eksport
          </a>
        </div>
      }
    </div>
  }
}
