import React,{Component} from 'react';


class Toast extends Component {

    render() {
        return (<h4 style={{fontWeight:500}}>{this.props.text}</h4>);
    }
}

export default Toast;
