import React,{Component} from 'react';
import Form from "react-jsonschema-form";
import {registerExpertProcess, changeExpertProcess} from "../services/expertProcess";
import {toStepConverter, registerProcess, updateProcess, getProcess} from "../services/core";
import FormButtons from "./FormButtons";
import styles from "./CustomForm.css";
import styled from 'styled-components';
import Toast from "./Toast";

const Wrapper = styled.div`
  margin: 100px auto 0 auto;
  padding: 100px;
  background: #2C3E50;
  width: 50%;
  box-shadow: 5px 10px 18px #888888;
  color: white;
  border-radius: 6px;
`;


class SteByStepForm extends Component {
    state = {
        step: 0,
        formData: {},
        jsonSchema: null,
        uiSchema: null,
        token: null,
        status: null
    };

    newProcess = (props = this.props) => {
        let link = props.match.params.link;
        if(link) {
            getProcess(link, this, true);
        }
    };

    componentWillReceiveProps(nextProps) {
        this.newProcess(nextProps);
    }

    componentDidMount() {
        this.newProcess();
    }

    onSubmit = ({formData}) => {
      let link = this.props.match.params.link;
        this.setState({
            step: this.state.step+1,
            formData: {
                ...this.state.formData,
                ...formData
            }
        },() => {

          console.log(this.state.step);
          console.log(this.state.jsonSchema.length);
          console.log(this.state.status);
          console.log(this.state.formData);

          if (this.state.step === this.state.jsonSchema.length ) {
            console.log(this.state.status);
            if (this.state.status == "inProgress" && this.state.status != "finish"){
              updateProcess(this.state.token, this.state.formData, this, true);
            }else if (this.state.status != "finish" && this.state.status != "inProgress") {
              registerProcess(link, JSON.stringify(this.state.formData), this, true, (callback) => {
                console.log(this.state.status);
              })
            }
          }

        });

    }

    previousField = () => {
        if(this.state.step > 0) {
            this.setState({
                step: this.state.step - 1
            });
        }
    }

    getSchemaPart = () => {
        if(this.state.step < this.state.jsonSchema.length ){
            return this.state.jsonSchema[this.state.step]
        }else{
            return this.state.jsonSchema[this.state.jsonSchema.length - 1]
        }
    }


    render() {
        if(!this.state.jsonSchema) return null;

        console.log(this.state.uiSchema);

        if(this.state.status === 'finish')
            return (<Wrapper><Toast text="Dziękujemy, dane zostały zapisane"/></Wrapper>);

        return (
            <Wrapper>
                <Form schema={this.getSchemaPart()}
                    uiSchema={this.state.uiSchema}
                    onSubmit={this.onSubmit}
                    formData={this.state.formData}>
                    <FormButtons forForm={this}/>
                </Form>
            </Wrapper>
        );

    }
}

export default SteByStepForm;
