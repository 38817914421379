import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {getActions, deleteAction} from '../services/actions'
import {ListStore} from "storwork-stores";
import {observer} from "mobx-react/index";
import FilterBar from '../FilterBar/FilterBar'
import Pagination from '../components/Pagination'
import ReactModal from 'react-modal'
import BusinessForm from 'components/BusinessForm';
import Avatar from 'react-avatar';
import {avatarUpload} from 'services/files'

const settings = {
  perPage: 8
};

export default props => <ActionList store={new ListStore({
  type: 'expertaction',
  size: settings.perPage,
  fullTextFields: ['unified_name']
})} />

const Button = props => <div className="w-1/2 p-1 text-center">
  {props.children}
</div>

const Card = props => <div className="min-w-sm p-2 sm:w-full lg:w-1/2 lg:flex float-left">
  <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
       title="Forms">
    <Avatar size="200" name={props.action.name} />
  </div>
  <div style={{width: '100%'}} className="border border-grey-light  lg:border-grey-light bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
    <div className="mb-8">
      <div className="text-black font-bold text-xl mb-2">{props.action.name}</div>
      <p className="text-grey-darker text-base">Utworzony {props.action.created_at.value ? moment(props.action.created_at.value).format("DD MMMM YYYY") + ' r.' : null}</p>
    </div>
    <div className="flex items-center">
      <Button>
        <Link to={'/edit-action/' + props.action.id}>Edytuj</Link>
      </Button>
      <Button>
        <Link to={'/processes/action/' + props.action.id}>Procesy({props.action.expertProcesses.length})</Link>
      </Button>
      {props.action.link && <Button>
          <div onClick={props.openModal} style={{cursor: 'pointer'}}>Embed</div>
        </Button>
      }
    </div>
    {/*<div className={'flex items-center'}>*/}
      {/*<Button><Link to={'/embed/' + props.action.link + '/Business'}>Biznes</Link></Button>*/}
      {/*<Button><Link to={'/embed/' + props.action.link + '/Business2'}>Biznes 2</Link></Button>*/}
    {/*</div>*/}
    {/*<div className={'flex items-center'}>*/}
      {/*<Button><Link to={'/embed/' + props.action.link + '/Custom'}>Użytkownika</Link></Button>*/}
      {/*<Button><Link to={'/embed/' + props.action.link + '/StepByStep'}>Krok po kroku</Link></Button>*/}
    {/*</div>*/}

  </div>
</div>

const IframeCode = props => {
  const src=window.location.origin + '/embed/' + props.link + '/Business';
  return <pre>{'<iframe src="' + src + '" frameborder="0" width="100%" height="100%"></iframe>'}</pre>;
};

const JsCode = props => {
  const jsSnippet = <pre>
    <div>{'<script src="https://forms.storwork.pl/embeds/cleanjs.js"></script>'}</div>
    <div>{'<script>'}</div>
    <div style={{paddingLeft: '16px'}}>{'window.registerExpertProcess("' + props.link + '", JSON.stringify(this.state), (callback) => {'}</div>
    <div style={{paddingLeft: '32px'}}>{'this.setState({'}</div>
    <div style={{paddingLeft: '48px'}}>{'success: true'}</div>
    <div style={{paddingLeft: '32px'}}>{'})'}</div>
    <div style={{paddingLeft: '16px'}}>{'});'}</div>
    <div>{'</script>'}</div>
  </pre>

  return jsSnippet;
};

export const handleAvatarFileUpload = (e, entityId, entityType) => {
  avatarUpload(e.target.files[0], entityId, entityType, () => {
    console.log(entityId + ' uploaded')
  })
}

export const AvatarUpload = (props) => {
  const img = props.hasAvatar ? props.img : '/default-avatar.png'

  const size = props.size ? props.size : '32px'
  const display = props.inline ? 'inline-block' : 'block'
  const margin = props.margin || 'auto'
  const marginTop = props.marginTop || '0px'
  const canUpload = props.canUpload || false

  return <React.Fragment>
    {canUpload ? <input ref={input => { this.avatarUpload = input }} style={{ display: 'none' }} type='file' onChange={(e) => handleAvatarFileUpload(e, props.id, props.type)} /> : null}
    <div style={{width: size, height: size, borderRadius: '50%', overflow: 'hidden', display, margin}} onClick={() => {
      // canUpload ? this.avatarUpload.click() : null
      console.log(this)
    }}>
      <img alt='avatar' style={{width: size, height: size, marginTop: marginTop, cursor: (canUpload ? 'pointer' : '')}} src={img} />
    </div>
  </React.Fragment>
}

const ActionList = observer(class _ActionList extends React.Component {
  state = {
    modal: false,
    action: false
  };

  render () {
    const store = this.props.store;
    const actions = store.records;

    return <div className="container mx-auto mt-2">
      <FilterBar store={store}/>
      {actions.map((action, key) => {
        return <Card key={action.id} action={action} num={key+1} openModal={() => this.setState({modal: true, action: action})}/>
      })}
      <div>
        <Pagination store={this.props.store} perPage={settings.perPage}/>
      </div>
      <div>
        <ReactModal
          isOpen={this.state.modal}
          onRequestClose={() => this.setState({modal: false})}
          shouldCloseOnOverlayClick={true}>

          <div><h2>IFRAME:</h2><IframeCode link={this.state.action.link} /></div><br/><br/><br/>
          <div><h2>JS:</h2><JsCode link={this.state.action.link} /></div>


        </ReactModal>
      </div>
    </div>
  }
})
