import React from "react";
import WrongInput from "./WrongInput.js";
import FontAwesome from "react-fontawesome";
import faStyles from "font-awesome/css/font-awesome.css";

// Props:
// label - text over input
// name - HTML name property
// onChange and (or) onBlur
// length to show diffrent styling
//  width
//  autofocus --> If you want to autofocus input field on site render. Wanted inside login and register

//SIDE EFFECT: PLEASE NOTE, THAT IF YOU DON'T PROVIDE ANY VALIDATION FOR INPUT IT'S RENDERING WILL BE INVALID
export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      didOnBlurInvoke: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  // prevents unnecessary input rerendering, happens only after component is fully mounted.
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.mounted) {
        if (this.props.value != nextProps.value)
            return true;
      if (this.props.length !== nextProps.length) {
        return true;
      }
      if (this.props.validated !== nextProps.validated) {
        return true;
      }
      if (this.state.didOnBlurInvoke !== nextState.didOnBlurInvoke) {
        return true;
      }
      return false;
    }
    return true;
  }

  insideInputIcon() {
    if (this.props.length > 0) {
      return (
        <div style={{ marginTop: "-20px" }}>
          <FontAwesome
            cssModule={faStyles}
            name={
              this.props.validated ? "check-circle" : "exclamation-triangle"
            }
            style={{
              position: "relative",
              bottom: "5px",
              //this ensures that font awesome displays on right side properly
              marginRight: 31 - this.props.width + "px",
              ...(this.props.validated
                ? {
                    color: "#4bb877"
                  }
                : {
                    color: "#ff7171"
                  })
            }}
          />
        </div>
      );
    }
  }

  handleOnBlur = () => {
    if (this.props.length > 0) {
      this.setState({
        didOnBlurInvoke: true
      });
    }
  };

  displayWarningBorder() {
    if (this.props.length > 0) {
      if (this.props.validated === false) {
        return "solid 1px rgba(255, 113, 113, 0.5)";
      } else if (this.props.validated) {
        return "solid 1px #dcdbde";
      }
    } else {
      return "solid 1px #dcdbde";
    }
  }

  render() {
    const {
      label,
      type,
      name,
      wrongInputMessage,
      validated,
      styles
    } = this.props;
    const { wrongInputStyle, didOnBlurInvoke } = this.state;

    const alignToWidth = Number(this.props.width) > 150 ? "center": "left";
    // console.log(alignToWidth)

    const wrapperStyle = {
      margin: "15px 0",
      position: "relative",
      textAlign: alignToWidth
    }

    const passwordInputStyle = {
      margin: "0 auto",
      outline: "none",
      padding: "0 10px",
      width: this.props.width + "px",
      height: "30px",
      display: "border-box",
      backgroundColor: !this.props.length ? "#f6f5f8" : "#fff",
      border: this.displayWarningBorder()
    };

    const passwordDivStyle = {
      width: this.props.width + "px",
      height: "30px",
      padding: "0px",
      display: "border-box",
      margin: "0 auto",
      backgroundColor: !this.props.length ? "#f6f5f8" : "#fff"
    };

    return (
      <div
        style={wrapperStyle}
        onClick={this.onInputClick}
        onBlur={this.handleOnBlur}
      >
        {label && (
          <label htmlFor={name} style={{ textIndent: this.props.indent ? this.props.indent + "px" : "10px" }}>
            {label}
          </label>
        )}
        <div className={{ ...passwordDivStyle }}>
          <input
            style={passwordInputStyle}
            type={type}
            id={name}
            name={name}
            value={this.props.value}
            onChange={this.props.onChange}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            ref={this.props.autoFocus && (input => input && input.focus())}
          />
          {this.insideInputIcon()}
        </div>
        {validated === false &&
          didOnBlurInvoke && <WrongInput>{wrongInputMessage}</WrongInput>}
      </div>
    );
  }
}
