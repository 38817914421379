export const createActionForm = () => {
  return {
    'json': {
      'type': 'object',
      'properties': {
        'name': {
          'title': 'Nazwa',
          'type': 'string'
        },
        'description': {
          'title': 'Opis',
          'type': 'string',
          'default': '[]'
        },
        'mapping': {
          'type':'object',
          'properties': {
            'src': {
              'title': 'Source',
              'type': 'string',
              'enum':[
                'pole z buildera 1',
                'pole z buildera 2',
              ]
            },
            'dest': {
              'title': 'Destination',
              'type': 'string',
              'enum':[
                'pole z bazy 1',
                'pole z bazy 2',
              ]
            }
          }
        },
        'type': {
          'title': 'Rodzaj',
          'type': 'string',
          'enum': [
            'start',
            'normal',
            'final',
            'main'
          ],
          'enumNames': [
            'Start',
            'Normal',
            'Final',
            'Main'
          ],
          'default': 'main'
        },
        'logic': {
          'title': 'Logika',
          'type': 'string',
          'default': 'return ["dane zapisane", "final", $context];'
        },
        'json_schema': {
          'title': 'JSON Schema',
          'type': 'string',
          'default': '{}'
        },
        'ui_schema': {
          'title': 'UI Schema',
          'type': 'string',
          'default': '{}'
        },
        'validation': {
          'title': 'Walidacja',
          'type': 'string'
        }
      },
      'required': ['name', 'type', 'json_schema']
    },
    'ui': {
      'name': {
        'ui:widget': 'text'
      },
      'description': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 3
        }
      },
      'type': {
        'ui:widget': 'hidden',
      },
      'logic': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 5
        }
      },
      'json_schema': {
        'ui:widget': 'formBuilder'
      },
      'ui_schema': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 8
        }
      },
      'validation': {
        'ui:widget': 'hidden',
        'ui:options': {
          'rows': 3
        }
      },
      'mapping':{
        'classNames': 'col-sm-12',
        'src': {
          'classNames':''
        },
        'dest': {
          'classNames':''
        }
      },
    }
  }
}
