import React from 'react'
import * as C from 'storwork-components'
import {observer} from 'mobx-react'
import _ from 'lodash'

export default observer(props => {
  const numberOfPages = Math.ceil(props.store.totalCount / (props.perPage ? props.perPage : 14))
  const currentPage = parseInt(props.store.getFilter('page') ? parseInt(props.store.getFilter('page')) : 1)

  const inRange = el => el >= 1 && el <= numberOfPages
  const shouldRender = [
    1, 2, 3, currentPage - 1, currentPage, currentPage + 1, numberOfPages - 2, numberOfPages - 1, numberOfPages
  ]
  const willRender = _.uniq(shouldRender.filter(inRange))

  const renderPage = (number) => <a key={number}
    onClick={() => props.store.setFilter('page', number)}
    style={{marginLeft: '10px', cursor: 'pointer', color: (number === currentPage ? '#2779bd' : '#ABABAB'), fontWeight: number === currentPage && 'bold'}}>{number}</a>
  return <C.Box align='center' padding='7px 0 0 0'>
    {willRender.map((el, ind) => <React.Fragment key={ind}>
      {ind > 0 && el - willRender[ind - 1] > 1 && ' ... '}
      {renderPage(el)}
    </React.Fragment>)}
  </C.Box>
})
