import React from "react";
import WrongInput from "./WrongInput.js";
import FontAwesome from "react-fontawesome";
import faStyles from "font-awesome/css/font-awesome.css";

// Props:
// label - text over input
// name - HTML name property
// onChange
// width
// styles - because context of css can change!
// length to show diffrent styling

//SIDE EFFECT: PLEASE NOTE, THAT IF YOU DON'T PROVIDE ANY VALIDATION FOR INPUT IT'S RENDERING WILL BE INVALID

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onEyeClick = e => {
    const type = this.state.type;
    let typeToBecome;
    if (type === "password") {
      typeToBecome = "text";
    } else if (type === "text") {
      typeToBecome = "password";
    }
    this.setState({
      type: typeToBecome
    });
  };

  insideInputIcon() {
    if (this.props.length > 0) {
      return (
        <div>
          <FontAwesome
            cssModule={faStyles}
            name={
              this.props.validated ? "check-circle" : "exclamation-triangle"
            }
            style={{
              position: "relative",
              bottom: "25px",
              //this ensures that font awesome displays on right side properly
              left: this.props.width - 24 + "px",
              ...(this.props.validated
                ? {
                    color: "#4bb877"
                  }
                : {
                    color: "#ff7171"
                  })
            }}
          />
        </div>
      );
    }
  }

  togglePasswordFontAwesome() {
    if (this.props.length > 0) {
      return (
        <div onClick={this.onEyeClick}>
          <FontAwesome
            cssModule={faStyles}
            name={this.state.type === "password" ? "eye" : "eye-slash"}
            style={{
              position: "relative",
              bottom: "45px",
              left: this.props.width - 49 + "px"
            }}
          />
        </div>
      );
    }
  }

  verifyProps = () => {
    this.setState({
      type: this.props.type
    });
  };

  componentDidMount() {
    this.verifyProps();
    this.setState({
      type: "password",
      mounted: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.mounted) {
      if (this.props.length !== nextProps.length) {
        return true;
      }
      if (this.props.validated !== nextProps.validated) {
        return true;
      }
      if (this.state.didOnBlurInvoke !== nextState.didOnBlurInvoke) {
        return true;
      }
      if (this.state.type !== nextState.type) {
        return true;
      }
      return false;
    }
    return true;
  }

  displayWarningBorder() {
    if (this.props.length > 0) {
      if (this.props.validated === false) {
        return "solid 1px rgba(255, 113, 113, 0.5)";
      } else if (this.props.validated) {
        return "solid 1px #dcdbde";
      }
    } else {
      return "solid 1px #dcdbde";
    }
  }

  render() {
    const { label, name, wrongInputMessage, validated, styles } = this.props;
    const { inputStyle, outerDivStyle, wrongInputStyle } = this.state;

    const wrapperStyle = {
      margin: "15px 0",
      position: "relative"
    };

    const passwordInputStyle = {
      margin: "0 auto",
      outline: "none",
      padding: "0 10px",
      width: this.props.width + "px",
      height: "30px",
      display: "border-box",
      backgroundColor: !this.props.length ? "#f6f5f8" : "#fff",
      border: this.displayWarningBorder()
    };

    const passwordDivStyle = {
      width: this.props.width + "px",
      height: "30px",
      padding: "0px",
      display: "border-box",
      margin: "0 auto",
      backgroundColor: !this.props.length ? "#f6f5f8" : "#fff"
    };

    return (
      <div style={wrapperStyle}>
        <label htmlFor={name} style={{ textIndent: "10px" }}>
          {label}
        </label>
        <div style={{ ...passwordDivStyle }}>
          <input
            style={passwordInputStyle}
            type={this.state.type}
            id={name}
            name={name}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
          />

          {this.insideInputIcon()}
          {this.togglePasswordFontAwesome()}
        </div>
        {validated === false && (
          <WrongInput
            outerDivStyle={{ top: "4px" }}
            textStyle={wrongInputStyle}
          >
            {wrongInputMessage}
          </WrongInput>
        )}
      </div>
    );
  }
}
