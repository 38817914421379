export const validateLength = (input, requiredLength = 2) => {
  let isValid;
  if (input.length < requiredLength) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
};

export default validateLength;
