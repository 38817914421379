import {changeExpertProcess, registerExpertProcess, getExpertAction, backExpertProcess} from "./expertProcess";


const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


export const toStepConverter  = (schema, ui) => {

    let stepsSchema = [];
    let properties = schema.properties;
    let dependencies = schema.dependencies;

    Object.keys(properties).map(function(key, val){
        let currentStep = properties[key];
        let stepNum = 'step_' + (val);

        let isRequired = [];
        if(schema.required.indexOf(key) !== -1) {
            isRequired = [key];
        }

        let stepObj = {
            title: schema.title + ' (krok ' + parseInt(val+1) + ' z ' + Object.keys(properties).length + ')',
            type: 'object',
            required: isRequired,
            properties: {
                [key]: currentStep
            },
            dependencies: (dependencies ? dependencies : {})
        };

        stepsSchema[val] = stepObj;
    });
    return stepsSchema;

}

export const renderForm = (data) => {


}

export const getProcess = (link, object, stepByStep = false) => {
  object.setState({
      jsonSchema: null,
      uiSchema: null,
      token: null,
      status: null,
      //formData: {},
      step: 0,
      showEnterButton: false
  });
  object.indicies = [];
  object.questions = [];
  object.inputs = [];

  getExpertAction(link, (data) => {

    let name = data.data.getExpertAction.name;
    let jsonSchema = JSON.parse(b64DecodeUnicode(data.data.getExpertAction.json_schema));
    let uiSchema = JSON.parse(b64DecodeUnicode(data.data.getExpertAction.ui_schema));
    let description = data.data.getExpertAction.description;
    let validation = data.data.getExpertAction.validation;
    let actions = JSON.parse('['+data.data.getExpertAction.id+']');

    object.setState({
        jsonSchema: ((stepByStep === false) ? jsonSchema : toStepConverter(jsonSchema)),
        uiSchema: uiSchema,
        name: name,
        description: description,
        validation: validation,
        actions: actions
    });
  })

}


export const registerProcess = (link, input, object, stepByStep = false ,callback = () => {}) => {
  object.setState({
    jsonSchema: null,
    uiSchema: null,
    token: null,
    status: null,
    formData: {},
    result: null,
    step: 0,
    name: null,
    showEnterButton: false
  });
  object.indicies = [];
  object.questions = [];
  object.inputs = [];

    registerExpertProcess(link, input, (data) => {

      console.log(data);

      let info = data.data.registerExpertProcess;
      console.log(info);
      let jsonSchema = JSON.parse(b64DecodeUnicode(info.expertActionJsonSchema));
      let result = info.result != null ? unescape(JSON.parse(info.result)) : null;

        object.setState({
            jsonSchema: ((stepByStep === false) ? jsonSchema : toStepConverter(jsonSchema)),
            uiSchema: JSON.parse(b64DecodeUnicode(info.expertActionUiSchema)),
            status: info.status,
            token: info.token,
            result: result,
            name: info.expertActionName,
            description: info.expertActionDescription,
            actions: JSON.parse(info.actions),
            validation: info.expertActionValidation,
        }, callback);
    })
}

export const updateProcess = (token, input, object, stepByStep = false, callback = () => {}) => {
    object.setState({
        jsonSchema: null,
        uiSchema: null,
        token: null,
        status: null,
        formData: {},
        result: null,
        step: 0,
        name: null,
        showEnterButton: false
    });
    object.indicies = [];
    object.questions = [];
    object.inputs = [];
    changeExpertProcess(token, JSON.stringify(input), (data) => {

      console.log(data);

        let changeExpertProcess = data.data.changeExpertProcess;
        let token = changeExpertProcess.token;
        let status = changeExpertProcess.status;
        let result = changeExpertProcess.result != null ? unescape(JSON.parse(changeExpertProcess.result)) : null;
        if(status === 'inProgress' || status === 'finish') {
            let jsonSchema = JSON.parse(b64DecodeUnicode(changeExpertProcess.expertActionJsonSchema));
            let uiSchema = JSON.parse(b64DecodeUnicode(changeExpertProcess.expertActionUiSchema));

            object.setState({
                jsonSchema: ((stepByStep === false) ? jsonSchema : toStepConverter(jsonSchema)),
                uiSchema: uiSchema,
                token: token,
                status: status,
                result: result,
                formData: JSON.parse(changeExpertProcess.context),
                name: changeExpertProcess.expertActionName,
                description: changeExpertProcess.expertActionDescription,
                validation: changeExpertProcess.expertActionValidation,
                actions: JSON.parse(changeExpertProcess.actions),
            }, callback);
        }
    });
}

export const backProcess = (token, input, action_id, object, stepByStep = false, callback = () => {}) => {
    object.setState({
        jsonSchema: null,
        uiSchema: null,
        token: null,
        status: null,
        formData: {},
        result: null,
        step: 0,
        name: null,
        showEnterButton: false
    });
    object.indicies = [];
    object.questions = [];
    object.inputs = [];
    backExpertProcess(token, JSON.stringify(input), action_id, (data) => {

      console.log(data);

        let backExpertProcess = data.data.BackExpertProcess;
        let token = backExpertProcess.token;
        let status = backExpertProcess.status;
        let result = backExpertProcess.result != null ? unescape(JSON.parse(backExpertProcess.result)) : null;
        if(status === 'inProgress' || status === 'finish') {
            let jsonSchema = JSON.parse(b64DecodeUnicode(backExpertProcess.expertActionJsonSchema));
            let uiSchema = JSON.parse(b64DecodeUnicode(backExpertProcess.expertActionUiSchema));

            object.setState({
                jsonSchema: ((stepByStep === false) ? jsonSchema : toStepConverter(jsonSchema)),
                uiSchema: uiSchema,
                token: token,
                status: status,
                result: result,
                formData: JSON.parse(backExpertProcess.context),
                name: backExpertProcess.expertActionName,
                description: backExpertProcess.expertActionDescription,
                validation: backExpertProcess.expertActionValidation,
                actions: JSON.parse(backExpertProcess.actions),
            }, callback);
        }
    });
}
