export default (query, vars, callback) => {
    fetch(process.env.REACT_APP_URL, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            query: query,
            variables: vars
        })
    })
        .then(response => {
            return response
                .json()
                .then(data => ({
                    ...data,
                    status: response.status
                }))
                .catch(err => {
                    console.log(err);
                });
        })
        .then(response => {
            if (
                response.errors
            ) {
                console.log("token expiry");
            } else if (response.errors) {
                // JWT.forget();
                // Note that this line may cause problems, forcing to change location if backend has problems or there is problem with fetching data
                // window.location = "/login";
            }
            return new Promise(function(resolve, reject) {
                resolve(response);
            });
        })
        .then(callback);
};
