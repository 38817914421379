import React from 'react'
import brace from 'brace'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/mode/php'
import 'brace/theme/github'

import { ToastContainer } from 'react-toastr'
import {createAction} from '../services/actions'

let container
export default class ActionsList extends React.Component {
  state = {
    name: '',
    description: '',
    type: 'start',
    logic: '',
    json_schema: '{}',
    ui_schema: '{}'
  };

  onSubmit (event) {
    createAction(this.state, data => {
      if (data.hasOwnProperty('errors')) {
        container.warning(`Dane nie zostały zapisane`, `info`, {
          closeButton: true,
          timeout: 1000
        })
      } else {
        container.success(`Dane zostały zapisane`, `info`, {
          closeButton: true
        })
      }
      console.log(data)
      // event.preventDefault();
    })
  }

  onChangeField (event) {
    event.persist()
    this.setState(state => {
      state[event.target.name] = event.target.value
      return state
    })
    console.log(this.state)
  }

  onChangeComponent (value, name) {
    this.setState(state => {
      state[name] = value
      return state
    })
    console.log(this.state)
  }

  render () {
    return (
      <div>
        <ToastContainer
          ref={ref => container = ref}
          className='toast-top-right'
        />
        <form>
          <div style={{ marginLeft: '200px' }}>
            <label>
            Action name:
              <input type='text' value={this.state.name} onChange={(e) => this.onChangeField(e)}
                name='name' className='form-control' style={{width: '600px'}} required />
            </label>
          </div>
          <div style={{marginLeft: '200px'}}>
            <label>
            Action description (progress):
              <AceEditor
                style={{height: '100px', width: '600px'}}
                showGutter={true}
                showPrintMargin={false}
                fontSize={16}
                mode='json'
                theme='github'
                onChange={(value) => this.onChangeComponent(value, 'description')}
                value={this.state.description}
                editorProps={{$blockScrolling: true}}
              />
            </label>
          </div>
          <div style={{marginLeft: '200px'}}>
            <label>
            Action type:
              <select name='type' value={this.state.type} onChange={(e) => this.onChangeField(e)}
                className='form-control' style={{width: '600px'}}>
                <option value='start'>start (początkowa akcja)</option>
                <option value='normal'>normal (akcja pośrednia)</option>
                <option value='final'>final (ostatnia akcja)</option>
                <option value='main'>main (tylko jedna akcja)</option>
              </select>
            </label>
          </div>
          <div style={{marginLeft: '200px'}}>
            <label>
            Action logic:
              <AceEditor
                style={{height: '200px', width: '600px'}}
                showGutter={true}
                showPrintMargin={false}
                fontSize={16}
                mode='php'
                theme='github'
                onChange={(value) => this.onChangeComponent(value, 'logic')}
                editorProps={{$blockScrolling: true}}
                value={this.state.logic}
              />
            </label>
          </div>
          <div style={{marginLeft: '200px'}}>
            <label>
            JSON Schema:
              <AceEditor
                style={{height: '200px', width: '600px'}}
                showGutter={true}
                showPrintMargin={false}
                fontSize={16}
                mode='javascript'
                theme='github'
                onChange={(value) => this.onChangeComponent(value, 'json_schema')}
                value={this.state.json_schema}
                editorProps={{$blockScrolling: true}}
              />
            </label>
          </div>
          <div style={{marginLeft: '200px'}}>
            <label>
            UI Schema:
              <AceEditor
                style={{height: '200px', width: '600px'}}
                showGutter={true}
                showPrintMargin={false}
                fontSize={16}
                mode='json'
                theme='github'
                onChange={(value) => this.onChangeComponent(value, 'ui_schema')}
                value={this.state.ui_schema}
                editorProps={{$blockScrolling: true}}
              />
            </label>
          </div>
          <div style={{marginLeft: '200px', marginBottom: '60px'}}>
            <input type='submit' value='save' onClick={(e) => this.onSubmit(e)} />
          </div>
        </form>
      </div>
    )
  }
}
