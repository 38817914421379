import React from "react";
//Takes as props outerDivStyle and textStyle.  outerDivStyle moves whole div, and textStyle only moves text

//Pass as props text you would like to print out as warning
const WrongInput = props => {
  return (
    <div style={props.outerDivStyle}>
      <div style={props.textStyle}>{props.children}</div>
    </div>
  );
};

export default WrongInput;
