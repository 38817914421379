import React, {Component} from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export default class ActionGenerate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: [],
      title: '',
      type: 'string',
      isRequired: false,
      editedIndex: '',
      editedTitle: '',
      editedType: 'string',
      editedIsRequired: false,
      showModal: false
    }
  }

  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (event) {
    let field = {
      title: this.state.title,
      type: this.state.type,
      isRequired: this.state.isRequired
    }

    this.setState({
      fields: [...this.state.fields, field]
    })

    event.preventDefault()
  }

  handleUpdateField (event) {
    let field = {
      title: this.state.editedTitle,
      type: this.state.editedType,
      isRequired: this.state.editedIsRequired
    }

    let fieldsCopy = this.state.fields
    fieldsCopy[this.state.editedIndex] = field
    this.setState({
      fields: fieldsCopy
    })

    this.setState({ showModal: false })
    event.preventDefault()
  }

  handleRemoveField (index) {
    this.setState({
      fields: this.state.fields.filter((_, i) => i !== index)
    })
  }

  handleOpenModal (index) {
    let activeField = this.state.fields[index]
    this.setState({
      showModal: true,
      editedIndex: index,
      editedTitle: activeField.title,
      editedType: activeField.type,
      editedIsRequired: activeField.isRequired
    })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  handleGenerateJson () {
    let required = []
    let fields = []
    let element = ''

    this.state.fields.map((field, index) => {
      element = `"${field.title}": { "type":"${field.type}", "title":"${field.title}"}`
      fields.push(element)
      required.push(`"${field.title}"`)
    }
    )

    console.log(fields.join())
    console.log(required.join())
  }

  render () {
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
          type:
            <select
              name='type'
              value={this.state.type}
              onChange={(e) => this.handleChange(e)}>
              <option value='string'>string</option>
              <option value='integer'>integer</option>
            </select>
          </label>
          <label>
          title:
            <input
              name='title'
              type='text'
              value={this.state.title}
              onChange={(e) => this.handleChange(e)}
            />
          </label>
          <label>
          is required:
            <input
              name='isRequired'
              type='checkbox'
              checked={this.state.isRequired}
              onChange={(e) => this.handleChange(e)}
            />
          </label>
          <input
            type='submit'
            value='dodaj pole'
          />
        </form>
        <br /><br />
      pola formularza:
        {this.state.fields.map((field, index) =>
          <div key={index} style={{border: 'solid', width: '600px'}}>
            <span>title:{field.title} </span>
            <span>type:{field.type} </span>
            <span>is required:{field.isRequired ? 'yes' : 'no'} </span>
            <button onClick={() => this.handleRemoveField(index)}>usuń</button>
            <button onClick={() => this.handleOpenModal(index)}>edytuj</button>
            <ReactModal isOpen={this.state.showModal}>
              {this.state.showModal === true &&
              <div>
                <button onClick={() => this.handleCloseModal()} style={{float: 'right'}}>zamknij okno</button>
                <form onSubmit={(e) => this.handleUpdateField(e)}>
                  <label>
                    type:
                    <select
                      name='editedType'
                      value={this.state.editedType}
                      onChange={(e) => this.handleChange(e)}>
                      <option value='string'>string</option>
                      <option value='integer'>integer</option>
                    </select>
                  </label>
                  <label>
                    title:
                    <input
                      name='editedTitle'
                      type='text'
                      value={this.state.editedTitle}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </label>
                  <label>
                    is required:
                    <input
                      name='editedIsRequired'
                      type='checkbox'
                      checked={this.state.editedIsRequired}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </label>
                  <input type='submit' value='zapisz' />
                </form>
              </div>
              }
            </ReactModal>
          </div>)}
        <button onClick={() => this.handleGenerateJson()}>zapisz formularz</button>
      </div>
    )
  }
}
