import React from 'react'
import _ from 'lodash'
import {ListStore} from 'storwork-stores'
import {observer} from 'mobx-react'
import ReactModal from 'react-modal'
import ReactJson from 'react-json-view'
import FilterBar from '../FilterBar/FilterBar'

import ProcessElement from '../components/ProcessElement'
import {getProcesses, deleteProcess} from '../services/processes'
import {exportToCsv} from '../services/exportToCsv'
import Main from "../Main/Main";
import Pagination from '../components/Pagination'

// ReactModal.setAppElement('#root')


const Cell = props => <div className='p-2'>{props.children}</div>
const Row = props => <div>
  <div className='float-left w-16 p-2'>{props.num}</div>
  <div className='float-left w-16 p-2'>{props.process.id}</div>
  <div className='float-left truncate w-64 p-2' title={props.process.expertAction.name}>{props.process.expertAction.name}</div>
  <div className='float-right w-48 p-2'>
    <span onClick={props.openModal} className='cursor-pointer'>więcej informacji</span>
    &nbsp;|&nbsp;
    usuń
  </div>
  <div className='truncate p-2'>{JSON.parse(props.process.result) || 'Brak'}</div>
</div>

const settings = {
  perPage: 20
};

const options = {
  type: 'expertprocess',
  size: settings.perPage,
  fullTextFields: ['unified_name']
};

export default props => {
  if(props.match.params.id) {
    options.staticFilters = {
      'expertAction.id': props.match.params.id
    };
  } else {
    delete options.staticFilters;
  }

  return <ProcessList id={props.match.params.id} store={new ListStore(options)} />
}

const ProcessList = observer(class _ProcessList extends React.Component {
  state = {
    actions: [],
    showModal: false,
    activeProcess: 0,
    modal: false,
    src: {}
  }

  getData = (props = this.props) => {
    getProcesses(processes => {
      this.setState({
        actions: processes.data.repository.expertActions.data
      })
    })
  };

  removeProcess = (id) => {
    deleteProcess(id, this.getData)
  };

  constructor() {
    super()
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal(proc) {
    this.setState({showModal: true, activeProcess: proc})
  }

  handleCloseModal() {
    this.setState({showModal: false})
  }

  componentDidMount() {
    // console.log(this.props.match);
    // this.getData()
    // setInterval(this.getData, 3000);
  }

  componentWillReceiveProps = (props) => {
    // console.log(props);
  }


  generateData(processes) {
    // console.log(processes);
    let header = []
    let data = []
    // console.log(processes);
    processes.map(process => {
      let information = process.context ? JSON.parse(process.context) : {}
      Object.keys(information).map((key, i) => {
        if (header.indexOf(key) === -1) header.push(key)
      })
    })
    // console.log(header);
    processes.map(process => {
      let information = process.context ? JSON.parse(process.context) : {}
      let row = header.map(el => {
        return Object.keys(information).indexOf(el) === -1 ? '' : information[el]
      })
      row.unshift(JSON.parse(process.result))
      row.unshift((process.status === 'finish') ? 'wypełniony' : 'nie wypełniony')
      row.unshift(process.id)
      data.push(row)
      // console.log(row);
    })
    header.unshift('Komunikat')
    header.unshift('Status formularza')
    header.unshift('Id')
    data.unshift(header)

    return data
  }

  render() {
    const store = this.props.store;
    const processes = store.records;
    return <React.Fragment>
      <div className='p-3'>
        <FilterBar store={store} csvData={this.generateData(processes)}/>
        {/*<Main/>*/}
      </div>
      <div className='font-light pt-3 pb-3'>
        <div className='float-left w-16 p-2'>#</div>
        <div className='float-left w-16 p-2'>#ID</div>
        <div className='float-left w-64 p-2'>Formularz</div>
        <div className='float-right w-48 p-2'>Akcje</div>
        <div className='truncate p-2'>Wynik</div>
      </div>
      <div className='overflow-auto striped'>
        {processes.map((el, key) => <Row key={key} openModal={() => this.setState({modal: true, src: JSON.parse(el.context)})} process={el} num={key+1} />)}
      </div>
      <div>
        <Pagination store={store} perPage={settings.perPage}/>
      </div>
      <ReactModal
        isOpen={this.state.modal}
        onRequestClose={() => this.setState({modal: false})}
        shouldCloseOnOverlayClick={true}
      >
        <ReactJson src={this.state.src} />
      </ReactModal>
    </React.Fragment>
  }
})
